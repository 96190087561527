import { type CouponOutcome, useCouponStore } from '../stores/useCouponStore'

export default defineNuxtPlugin({
  order: 11,
  async setup(nuxt) {
    const couponStore = useCouponStore()
    const { $marketsStore } = useNuxtApp()
    couponStore.setMarketsStore($marketsStore)

    nuxt.hook('app:mounted', () => {
      window.addEventListener('click', (event) => {
        const dataset: Record<string, string> =
          (event as any)?.target?.dataset ?? {}

        if (dataset.todo !== 'to_coupon') return

        const { eventId, marketId, marketSpecifiers, uniqId, outcomeId } =
          dataset

        const outcome: CouponOutcome = {
          eventId: Number(eventId),
          marketId: Number(marketId),
          marketSpecifiers,
          uniqId,
          outcomeId: Number(outcomeId),
        }

        if (couponStore.hasOutcome(outcome.uniqId)) {
          couponStore.removeOutcome(outcome.uniqId)
        } else {
          couponStore.addOutcome(outcome)
        }
      })
    })
  },
})
