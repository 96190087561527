<template>
  <component
    :is="component"
    :type="nativeType"
    :class="classes"
    :disabled="isDisabled"
    :data-t="dataT"
    :to="to"
    :target="target"
    :replace="replace"
  >
    <StSpinner v-if="loading" :size="iconSize" class="button-loader" />
    <StIcon
      v-if="isLeftIcon && icon"
      class="button-icon"
      :name="icon"
      :size="iconSize"
      data-t="st-icon-left"
    />
    <slot>
      {{ label }}
    </slot>
    <StIcon
      v-if="!isLeftIcon && icon"
      class="button-icon"
      :name="icon"
      :size="iconSize"
      data-t="st-icon-right"
    />
  </component>
</template>

<script setup lang="ts">
import NuxtI18nLink from '@st/i18n/components/NuxtI18nLink.vue'
import type { ButtonHTMLAttributes } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type { IconName } from '../StIcon/types'
import type { ButtonSize, ButtonType } from './types'

const props = withDefaults(
  defineProps<{
    label?: string
    dataT?: string
    submit?: boolean
    size?: ButtonSize
    type?: ButtonType
    disabled?: boolean
    loading?: boolean
    to?: RouteLocationRaw
    icon?: IconName
    block?: boolean
    isLeftIcon?: boolean
    target?: '_blank' | '_self' | '_parent' | '_top'
    replace?: boolean
    customColor?: string
  }>(),
  {
    submit: false,
    size: 'l',
    type: 'primary',
    disabled: false,
    loading: false,
    dataT: 'st-button',
    block: false,
    isLeftIcon: false,
    customColor: '#ffffff',
  },
)

const component = computed(() => {
  if (props.to) return NuxtI18nLink
  return 'button'
})

const nativeType = computed<ButtonHTMLAttributes['type']>(() =>
  props.submit ? 'submit' : 'button',
)

const isDisabled = computed(() => props.disabled || props.loading)

const isOnlyIcon = computed(() => props.icon && !props.label)
const iconSizeMap = computed(() => ({
  s: 16,
  m: isOnlyIcon.value ? 20 : 16,
  l: isOnlyIcon.value ? 24 : 20,
  xl: 24,
  '2xl': 24,
}))

const iconSize = computed(() => iconSizeMap.value[props.size] ?? 16)

const classes = computed(() => [
  'st-button',
  `type-${props.type}`,
  `size-${props.size}`,
  {
    'only-icon': isOnlyIcon.value,
    loading: props.loading,
    block: props.block,
    disabled: props.disabled,
  },
])
</script>

<style scoped>
.st-button {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--text-primary);
  text-decoration: none;

  background-color: transparent;
  border: none;
  border-radius: var(--border-radius-100);
  outline: none;

  transition:
    background-color 0.1s,
    box-shadow 0.1s,
    color 0.1s;

  &.block {
    width: 100%;
  }

  &.loading {
    color: transparent;

    > .button-icon {
      visibility: hidden;
    }

    > .button-loader {
      position: absolute;
      color: var(--text-primary);
    }
  }

  &.size-s {
    gap: var(--spacing-050);
    padding: var(--spacing-050) var(--spacing-100);
    font: var(--desktop-text-xs-semibold);

    &.only-icon {
      padding: var(--spacing-050);
    }
  }

  &.size-m {
    gap: var(--spacing-075);
    padding: var(--spacing-075) var(--spacing-150);
    font: var(--desktop-text-sm-semibold);

    &.only-icon {
      padding: var(--spacing-100);
    }
  }

  &.size-l {
    gap: var(--spacing-100);
    padding: var(--spacing-125) var(--spacing-200);
    font: var(--desktop-text-sm-semibold);

    &.only-icon {
      padding: var(--spacing-125);
    }
  }

  &.size-xl {
    gap: var(--spacing-100);
    padding: var(--spacing-150) var(--spacing-250);
    font: var(--desktop-text-md-semibold);

    &.only-icon {
      padding: var(--spacing-150);
    }
  }

  &.size-2xl {
    gap: var(--spacing-150);
    padding: var(--spacing-200) var(--spacing-350);
    font: var(--desktop-text-lg-semibold);

    &.only-icon {
      padding: var(--spacing-200);
    }
  }

  &:disabled {
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.32;
  }

  &.type-primary {
    background-color: var(--button-primary-default);

    &.loading {
      background-color: var(--button-primary-active);
    }

    &:hover {
      background-color: var(--button-primary-hover);
    }

    &:focus-visible {
      background-color: var(--button-primary-hover);
      box-shadow: var(--focus-rings-button-primary);
    }

    &:active {
      background-color: var(--button-primary-active);
    }
  }

  &.type-secondary {
    background-color: var(--button-secondary-default);

    &.loading {
      background-color: var(--button-secondary-active);
    }

    &:hover {
      background-color: var(--button-secondary-hover);
    }

    &:focus-visible {
      background-color: var(--button-secondary-hover);
      box-shadow: var(--focus-rings-button-secondary);
    }

    &:active {
      background-color: var(--button-secondary-active);
    }
  }

  &.type-gray {
    background-color: var(--button-gray-default);

    &:hover {
      background-color: var(--button-gray-hover);
    }

    &:focus-visible {
      background-color: var(--button-gray-hover);
      box-shadow: var(--focus-rings-button-gray);
    }

    &:active {
      background-color: transparent;
    }

    &.loading {
      background-color: transparent;
    }
  }

  &.type-ghost {
    background-color: var(--button-ghost-default);

    &.loading {
      background-color: var(--button-ghost-active);
    }

    &:hover {
      background-color: var(--button-ghost-hover);
    }

    &:focus-visible {
      background-color: var(--button-ghost-hover);
      box-shadow: var(--focus-rings-button-ghost);
    }

    &:active {
      background-color: var(--button-ghost-active);
    }
  }

  &.type-destructive {
    padding: 0;
    background-color: var(--button-destructive-default);

    &.loading {
      background-color: var(--button-destructive-active);
    }

    &:hover {
      background-color: var(--button-destructive-hover);
    }

    &:focus-visible {
      background-color: var(--button-destructive-hover);
      box-shadow: var(--focus-rings-button-destructive);
    }

    &:active {
      background-color: var(--button-destructive-active);
    }
  }

  &.type-custom-color {
    background-color: v-bind(customColor);

    &.loading {
      background-color: var(--button-ghost-active);
    }

    &:hover {
      filter: brightness(115%);
    }

    &:focus-visible {
      filter: brightness(115%);
      box-shadow: 0 0 0 4px v-bind(customColor);
    }

    &:active {
      filter: brightness(90%);
    }
  }

  &.type-text-primary {
    padding: 0;
    color: var(--button-primary-default);

    &:hover {
      color: var(--button-primary-hover);
    }

    &:focus-visible {
      color: var(--button-primary-hover);
      box-shadow: var(--focus-rings-button-primary);
    }

    &:active {
      color: var(--button-primary-active);
    }
  }

  &.type-text-secondary {
    padding: 0;
    color: var(--text-secondary);

    &:hover {
      color: var(--text-primary);
    }

    &:focus-visible {
      color: var(--text-primary);
      box-shadow: var(--focus-rings-button-ghost);
    }

    &:active {
      color: var(--text-tertiary);
    }
  }

  &.type-text-tertiary {
    padding: 0;
    color: var(--text-tertiary);

    &:hover {
      color: var(--text-primary);
    }

    &:focus-visible {
      color: var(--text-primary);
      box-shadow: var(--focus-rings-button-ghost);
    }

    &:active {
      color: var(--text-secondary);
    }
  }

  &.type-text-destructive {
    padding: 0;
    color: var(--button-destructive-default);

    &:hover {
      color: var(--button-destructive-hover);
    }

    &:focus-visible {
      color: var(--button-destructive-hover);
      box-shadow: var(--focus-rings-button-destructive);
    }

    &:active {
      color: var(--button-destructive-active);
    }
  }

  &.type-text-only {
    padding: 0;
    font: var(--desktop-text-sm-semibold);
    color: var(--text-link);

    &.size-s {
      font: var(--desktop-text-xs-semibold);
    }

    &:hover {
      color: var(--button-primary-hover);
    }

    &:active {
      color: var(--button-primary-active);
    }
  }
}
</style>
