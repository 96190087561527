import { deepClone, mockDateWithOffset } from '@st/utils'
import { HttpResponse, stHttp, delay } from '../st-msw'

// Этот мок используется для тестирования API. Именно его нельзя удалять
export const postDepositAddressGet = stHttp.post('/deposit/address/get', () =>
  HttpResponse.json({
    address: '09hf935f-d0i23y980sf-d0i23y98ug49fb8yvdf0-0j504jbx908e',
    mercuryoSignature: '',
  }),
)

export const postCasinoGameInitError = stHttp.post(
  '/casino/game/init/demo',
  () =>
    HttpResponse.json(
      {
        error: 'CASINO_GAME_UNAVAILABLE',
      },
      {
        status: 403,
      },
    ) as any,
)

export const postCasinoGameInitRestriction = stHttp.post(
  '/casino/game/init/demo',
  async () => {
    await delay(300)

    return HttpResponse.json({
      url: 'http://192.168.1.121:3000/casino/restriction',
    })
  },
)

const postWelcomeBonus = stHttp.post('/welcome-program/info/get', async () => {
  await delay(50)
  return HttpResponse.json({
    freebetAmount: '100',
    freebetCurrencyId: 1,
    freebetRollingMultiplier: '1',
    freebetRollingPeriodInHours: 48,
    freebetRollingRollingCasinoCategoryIds: [59, 60],
    freebetRollingRollingMinBetRate: '1.15',
    freebetRollingRollingMaxBetRate: '1.25',

    reloadProgramAmount: '25',
    reloadProgramCurrencyId: 3,
    reloadProgramRepeatIntervalInHours: 96,

    freespinAmount: '100',
    freespinProgramCurrencyId: 9,
    freespinNumber: 10,
    freespinProgramRollingMultiplier: '1',
    freespinProgramRollingDurationPeriodInHours: 24,
    freespinProgramRollingCasinoCategoryIds: [59, 60],
    freespinProgramRollingMinBetRate: '1.15',
    freespinProgramRollingMaxBetRate: '1.25',

    bonusForDepositDepositMultiplier: '100',
    bonusForDepositMinDepositAmountInAppCurrency: '50',
    bonusForDepositMaxBonusAmountInBonusCurrency: '1000',
    bonusForDepositCurrencyId: 1,
    bonusForDepositRollingMinBetRate: '1.15',
    bonusForDepositRollingMaxBetRate: '1.15',
    bonusForDepositRollingCasinoCategoryIds: [59, 60],
  })
})

export const postAuthLoginCodeSend = stHttp.post(
  '/auth/login/email/code/send',
  () =>
    /* @ts-expect-error forbidden error */
    HttpResponse.json(
      {
        error: 'FORBIDDEN_COUNTRY',
        data: {
          countryCode: 'blr',
          ip: '192.01.02.32',
        },
      },
      { status: 403 },
    ),
)

export const geoAvailabilityAssert = stHttp.get(
  '/geo/availability/assert',
  () =>
    /* @ts-expect-error forbidden error */
    HttpResponse.json(
      {
        error: 'FORBIDDEN_COUNTRY',
        data: {
          countryCode: 'blr',
          ip: '192.01.02.32',
        },
      },
      { status: 403 },
    ),
)

const tournamentMock = {
  id: 1,
  name: 'Общий',
  description: '',
  previewBannerUrl: 'https://placehold.co/416x184/38003D/38003D',
  bannerUrl: 'https://placehold.co/416x184/38003D/38003D',
  currencyId: 1,
  status: 'open',
  type: 'all',
  createdAt: '',
  validDateFrom: '',
  validDateTo: '2024-05-15T11:58:51.469Z',
  lastCalculatedAt: '',
  prizePoolType: 'dynamic',
  totalPrizePoolAmount: '1000',
  membersNumber: 100,
  prizePlaceNumber: 100,
  prizeTopList: [
    {
      prizePlace: 1,
      userNickname: 'Пуппа',
      userDropAmount: '100245.53',
      prizeAmount: '6000',
    },
    {
      prizePlace: 2,
      userNickname: 'Лупа',
      userDropAmount: '86871.92',
      prizeAmount: '1600',
    },
    {
      prizePlace: 3,
      userNickname: null,
      userDropAmount: '66871.92',
      prizeAmount: '750',
    },
    {
      prizePlace: 100,
      userNickname: null,
      userDropAmount: '800.92',
      prizeAmount: '2',
    },
  ],
}

export const postRatRaces = stHttp.post(
  '/rat-race/find',
  async ({ request }) => {
    const params = await request.json()
    const isProcessed = params?.params?.status?.includes('processed')
    const isCasinoType = params?.params?.type?.includes('casino')
    const response = deepClone([
      {
        ...tournamentMock,
        status: isProcessed ? 'processed' : 'open',
      },
      {
        ...tournamentMock,
        id: 2,
        status: isProcessed ? 'processed' : 'open',
      },
      {
        ...tournamentMock,
        id: 3,
        status: isProcessed ? 'processed' : 'open',
      },
      {
        ...tournamentMock,
        id: 6,
        status: isProcessed ? 'processed' : 'open',
      },
      {
        ...tournamentMock,
        id: 4,
        status: isProcessed ? 'processed' : 'open',
      },
      {
        ...tournamentMock,
        id: 5,
        status: isProcessed ? 'processed' : 'open',
      },
    ])
    if (isCasinoType) response.pop()

    return HttpResponse.json({
      paging: {
        total: 12,
        count: 12,
        page: 1,
      },
      data: response as any,
    })
  },
)

export const getUserVerification = stHttp.get(
  /* @ts-expect-error forbidden error */
  '/user-verification/active/get',
  () =>
    HttpResponse.json({
      data: {
        qwe: 'qwe',
      },
    }),
)

export const postGeoAssert = stHttp.get('/geo/availability/assert', () =>
  /* @ts-expect-error forbidden error */
  HttpResponse.json(
    {
      error: 'FORBIDDEN_COUNTRY',
      data: {
        ip: '127.0.0.1',
        countryCode: 'RUS',
      },
    },
    { status: 403 },
  ),
)

export const postFreebetFind = stHttp.post('/freebet/find', () =>
  HttpResponse.json({
    paging: {
      total: 1,
      page: 0,
      count: 1,
    },
    data: [
      {
        id: 126,
        accountId: 197,
        createdAt: '2024-06-10T20:50:14.897Z',
        amount: '10',
        expiredAt: '2024-06-11T20:50:14.897Z',
        processed: false,
        reasonForGetting: 'welcomeProgram',
        payoff: null,
        betId: null,
        rollingStartedAt: null,
        currentRollingAmount: '0',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1.00',
          maxBetRate: '2.00',
        },
        freebetRollingSettings: {
          rollingMultiplier: '11.00',
          rollingCasinoCategoryIds: [58],
          rollingPeriodInHours: 1,
          rollingMinBetRate: '1.00',
          rollingMaxBetRate: '2.00',
        },
      },
      {
        id: 129,
        accountId: 197,
        createdAt: '2024-06-10T20:50:14.897Z',
        amount: '10',
        expiredAt: '2024-06-11T20:50:14.897Z',
        processed: false,
        reasonForGetting: 'welcomeProgram',
        payoff: null,
        betId: 1227,
        rollingStartedAt: null,
        currentRollingAmount: '0',
        freebetSettings: {
          allowedBetTypes: ['ordinary'],
          minBetRate: '1.00',
          maxBetRate: '2.00',
        },
        freebetRollingSettings: {
          rollingMultiplier: '12.00',
          rollingCasinoCategoryIds: [60],
          rollingPeriodInHours: 1,
          rollingMinBetRate: '1.00',
          rollingMaxBetRate: '2.00',
        },
      },
      {
        id: 29,
        accountId: 197,
        createdAt: '2024-06-10T15:18:16.496Z',
        amount: '50',
        expiredAt: '2024-06-11T15:18:16.496Z',
        processed: false,
        reasonForGetting: 'welcomeProgram',
        payoff: '36',
        betId: 103,
        rollingStartedAt: '2024-06-10T15:25:31.543Z',
        currentRollingAmount: '300',
        freebetSettings: {
          allowedBetTypes: ['ordinary', 'express'],
          minBetRate: '1.30',
          maxBetRate: '9999.99',
        },
        freebetRollingSettings: {
          rollingMultiplier: '25',
          rollingCasinoCategoryIds: [60, 58],
          rollingPeriodInHours: 168,
          rollingMinBetRate: '1.30',
          rollingMaxBetRate: '9999.99',
        },
      },
    ],
  }),
)

export const postFreespinFind = stHttp.post('/user-freespin-program/find', () =>
  HttpResponse.json({
    paging: {
      total: 1,
      page: 0,
      count: 1,
    },
    data: [
      {
        userFreespinProgramId: 84,
        status: 'new',
        createdAt: '2024-06-10T20:50:14.897Z',
        expiredAt: mockDateWithOffset({ minutes: 15 }),
        freespinCurrencyId: 9,
        rewardCurrencyId: 2,
        freespinNumber: 10,
        rollingMultiplier: '2',
        rollingDurationPeriodInHours: 1,
        rollingCurrentAmount: '0',
        rollingFullAmount: '0',
        freespinCurrentRewardAmount: '0',
        freespinCurrentRewardAmountInRewardCurrency: '0',
        freespinNumberLeft: null,
        freespinGameId: null,
        casinoGameToChooseIds: [563, 9606],
        freespinBetAmount: '0.1',
        maxRewardAmount: '10000',
        rollingCasinoCategoryIds: [60],
        rollingMinBetRate: '1.00',
        rollingMaxBetRate: '3.00',
      },
      {
        userFreespinProgramId: 84,
        status: 'inProgress',
        createdAt: '2024-06-10T20:50:14.897Z',
        expiredAt: mockDateWithOffset({ hours: 1 }),
        freespinCurrencyId: 9,
        rewardCurrencyId: 2,
        freespinNumber: 13,
        rollingMultiplier: '3',
        rollingDurationPeriodInHours: 1,
        rollingCurrentAmount: '0',
        rollingFullAmount: '0',
        freespinCurrentRewardAmount: '31.42142',
        freespinCurrentRewardAmountInRewardCurrency: '31.42142',
        freespinNumberLeft: 4,
        freespinGameId: 563,
        casinoGameToChooseIds: [563, 9606],
        freespinBetAmount: '0.1',
        maxRewardAmount: '10000',
        rollingCasinoCategoryIds: [],
        rollingMinBetRate: null,
        rollingMaxBetRate: null,
      },
      {
        userFreespinProgramId: 27,
        status: 'inProgress',
        createdAt: '2024-06-10T15:18:16.496Z',
        expiredAt: mockDateWithOffset({ hours: 5, minutes: 10 }),
        freespinCurrencyId: 8,
        rewardCurrencyId: 3,
        freespinNumber: 50,
        rollingMultiplier: '50.00',
        rollingDurationPeriodInHours: 48,
        rollingCurrentAmount: '23.32123',
        rollingFullAmount: '552.16860325',
        freespinCurrentRewardAmount: '59.08674413',
        freespinCurrentRewardAmountInRewardCurrency: '59.08674413',
        freespinNumberLeft: 0,
        freespinGameId: 5927,
        casinoGameToChooseIds: [11018, 8358, 2366, 5312, 2380],
        freespinBetAmount: '1',
        maxRewardAmount: '10000',
        rollingCasinoCategoryIds: [58],
        rollingMinBetRate: '1.00',
        rollingMaxBetRate: '3.00',
      },
      {
        userFreespinProgramId: 26,
        status: 'rolling',
        createdAt: mockDateWithOffset(),
        expiredAt: mockDateWithOffset({ days: 7 }),
        freespinCurrencyId: 8,
        rewardCurrencyId: 1,
        freespinNumber: 50,
        rollingMultiplier: '25.00',
        rollingDurationPeriodInHours: 168,
        rollingCurrentAmount: '200.32123',
        rollingFullAmount: '552.16860325',
        freespinCurrentRewardAmount: '22.08674413',
        freespinCurrentRewardAmountInRewardCurrency: '22.08674413',
        freespinNumberLeft: 0,
        freespinGameId: 5927,
        casinoGameToChooseIds: [11018, 8358, 2366, 5312, 2380],
        freespinBetAmount: '1',
        maxRewardAmount: '10000',
        rollingCasinoCategoryIds: [58],
        rollingMinBetRate: '1.00',
        rollingMaxBetRate: '3.00',
      },
    ],
  }),
)

export const postCashbackList = stHttp.post('/cashback/list', () =>
  HttpResponse.json([
    {
      bonusProgramId: 1,
      currencyId: 3,
      accrualAt: mockDateWithOffset({ hours: 2 }),
    },
    // {
    //   bonusProgramId: 16,
    //   currencyId: 2,
    //   accrualAt: mockDateWithOffset({ hours: 15 }),
    // },
  ]),
)

export const postReloadProgramList = stHttp.post('/reload-program/list', () =>
  HttpResponse.json([
    {
      bonusProgramId: 1,
      amount: '1.25',
      currencyId: 1,
      accrualAt: mockDateWithOffset({ hours: 15 }),
    },
  ]),
)

export const postBonusFind = stHttp.post('/bonus/find', () =>
  HttpResponse.json({
    paging: {
      page: 0,
      count: 0,
      total: 3,
    },
    data: [
      {
        id: 1,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'rakeBack',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ hours: 23 }),
        amount: '1.25',
      },
      {
        id: 12,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'cashback',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ hours: 23 }),
        amount: '1.25',
      },
      {
        id: 13,
        currencyId: 1,
        createdAt: '2024-07-11T13:00:07.705Z',
        bonusProgramType: 'reload',
        bonusProgramId: 1,
        claimed: false,
        expiredAt: mockDateWithOffset({ hours: 23 }),
        amount: '1.25',
      },
    ],
  }),
)

export const postDepositCalypsoInit = stHttp.post(
  '/deposit/calypso/init',
  async () => {
    await delay(1000)
    return HttpResponse.json({
      calypsoDepositLink:
        'https://www.youtube.com/embed/hik6n3odVy8?si=VvAJGzuZSwITc8lM',
    })
  },
)

export const postDepositRollingDelta = stHttp.post(
  '/user/deposit-rolling-delta',
  () =>
    HttpResponse.json({
      depositRollingDelta: '-10',
    }),
)

export const postTariff = stHttp.post('/payment-tariff/get', () =>
  HttpResponse.json({
    id: 19,
    isDefault: true,
    name: 'string',
    createdAt: '2024-04-11T12:29:48.711Z',
    fees: [
      {
        id: 88,
        currencyId: 1,
        networkId: 3,
        name: 'Комиссии на депозит USDT BEP20',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.182303+02:00',
        zeroFeeFromAmount: '1.00000000',
      },
      {
        id: 93,
        currencyId: 2,
        networkId: 2,
        name: 'Комиссии на депозит USDC ERC20',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.635038+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 94,
        currencyId: 2,
        networkId: 3,
        name: 'Комиссии на депозит USDC BEP20',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.720465+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 97,
        currencyId: 3,
        networkId: 1,
        name: 'Комиссии на депозит BTC',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.987588+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 99,
        currencyId: 5,
        networkId: 3,
        name: 'Комиссии на депозит BNB BEP20',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.164153+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 101,
        currencyId: 4,
        networkId: 2,
        name: 'Комиссии на депозит ETH Ethereum',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.334328+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 103,
        currencyId: 6,
        networkId: 5,
        name: 'Комиссии на депозит LTC Litecoin',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.51565+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 105,
        currencyId: 7,
        networkId: 4,
        name: 'Комиссии на депозит TRX Tron',
        operationType: 'deposit',
        minAmount: '0.10000000',
        maxAmount: '5.00000000',
        fix: '0.01000000',
        percent: '0.10',
        createdAt: '2024-04-11T14:11:49.690129+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 87,
        currencyId: 1,
        networkId: 2,
        name: 'Комиссии на депозит USDT ERC20',
        operationType: 'deposit',
        minAmount: '0.10000000',
        maxAmount: '99999999.00000000',
        fix: '1.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.083204+02:00',
        zeroFeeFromAmount: '9999999999999999.00000000',
      },
      {
        id: 92,
        currencyId: 1,
        networkId: 4,
        name: 'Комиссии на вывод USDT TRC20',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.536439+02:00',
        zeroFeeFromAmount: '100.00000000',
      },
      {
        id: 95,
        currencyId: 2,
        networkId: 2,
        name: 'Комиссии на вывод USDC ERC20',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.01000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.810652+02:00',
        zeroFeeFromAmount: '100.00000000',
      },
      {
        id: 96,
        currencyId: 2,
        networkId: 3,
        name: 'Комиссии на вывод USDC BEP20',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.900074+02:00',
        zeroFeeFromAmount: '100.00000000',
      },
      {
        id: 90,
        currencyId: 1,
        networkId: 2,
        name: 'Fee USDT REC20 TEST',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '10000.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.362294+02:00',
        zeroFeeFromAmount: '100.00000000',
      },
      {
        id: 98,
        currencyId: 3,
        networkId: 1,
        name: 'Комиссии на вывод BTC',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.071722+02:00',
        zeroFeeFromAmount: '0.00150000',
      },
      {
        id: 102,
        currencyId: 4,
        networkId: 2,
        name: 'Комиссии на вывод ETH Ethereum',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.426468+02:00',
        zeroFeeFromAmount: '0.03000000',
      },
      {
        id: 100,
        currencyId: 5,
        networkId: 3,
        name: 'комиссия вывод bnb',
        operationType: 'withdrawal',
        minAmount: '0.00000001',
        maxAmount: '9000000000000.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.248909+02:00',
        zeroFeeFromAmount: '0.17000000',
      },
      {
        id: 104,
        currencyId: 6,
        networkId: 5,
        name: 'Комиссии на вывод LTC Litecoin',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:49.595734+02:00',
        zeroFeeFromAmount: '1.20000000',
      },
      {
        id: 106,
        currencyId: 7,
        networkId: 4,
        name: 'Комиссии на вывод TRX Tron',
        operationType: 'withdrawal',
        minAmount: '0.10000000',
        maxAmount: '5.00000000',
        fix: '0.01000000',
        percent: '0.10',
        createdAt: '2024-04-11T14:11:49.776512+02:00',
        zeroFeeFromAmount: '20000',
      },
      {
        id: 106,
        currencyId: 7,
        networkId: 4,
        name: 'Комиссии на вывод TRX Tron',
        operationType: 'withdrawalUnrolled',
        minAmount: '5',
        maxAmount: '20',
        fix: '10',
        percent: '0.30',
        createdAt: '2024-04-11T14:11:49.776512+02:00',
        zeroFeeFromAmount: '20000',
      },
      {
        id: 89,
        currencyId: 1,
        networkId: 4,
        name: 'Комиссии на депозит USDT TRC20',
        operationType: 'deposit',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.269753+02:00',
        zeroFeeFromAmount: '1.00000000',
      },
      {
        id: 91,
        currencyId: 1,
        networkId: 3,
        name: 'Комиссии на вывод USDT BEP20',
        operationType: 'withdrawal',
        minAmount: '0.00000000',
        maxAmount: '99999999.00000000',
        fix: '0.00000000',
        percent: '0.00',
        createdAt: '2024-04-11T14:11:48.448099+02:00',
        zeroFeeFromAmount: '1.00000000',
      },
    ],
    limits: [
      {
        id: 93,
        currencyId: 1,
        networkId: 2,
        name: 'Лимиты на депозит USDT ERC20',
        operationType: 'deposit',
        minAmount: '1.00000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.079748+02:00',
      },
      {
        id: 94,
        currencyId: 1,
        networkId: 3,
        name: 'Лимиты на депозит USDT BEP20',
        operationType: 'deposit',
        minAmount: '0.10000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.300417+02:00',
      },
      {
        id: 95,
        currencyId: 1,
        networkId: 4,
        name: 'Лимиты на депозит USDT TRC20',
        operationType: 'deposit',
        minAmount: '0.10000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.38567+02:00',
      },
      {
        id: 98,
        currencyId: 1,
        networkId: 4,
        name: 'Лимиты на вывод USDT TRC20',
        operationType: 'withdrawal',
        minAmount: '0.10000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.674365+02:00',
      },
      {
        id: 99,
        currencyId: 2,
        networkId: 2,
        name: 'Лимиты на депозит USDC ERC20',
        operationType: 'deposit',
        minAmount: '1.00000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.75952+02:00',
      },
      {
        id: 100,
        currencyId: 2,
        networkId: 3,
        name: 'Лимиты на депозит USDC BEP20',
        operationType: 'deposit',
        minAmount: '0.10000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.851758+02:00',
      },
      {
        id: 101,
        currencyId: 2,
        networkId: 2,
        name: 'Лимиты на вывод USDC ERC20',
        operationType: 'withdrawal',
        minAmount: '1.00000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.939952+02:00',
      },
      {
        id: 102,
        currencyId: 2,
        networkId: 3,
        name: 'Лимиты на вывод USDC BEP20',
        operationType: 'withdrawal',
        minAmount: '0.10000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:49.031116+02:00',
      },
      {
        id: 103,
        currencyId: 3,
        networkId: 1,
        name: 'Лимиты на депозит BTC',
        operationType: 'deposit',
        minAmount: '0.00001500',
        maxAmount: '0.00010000',
        createdAt: '2024-04-11T14:11:49.115316+02:00',
      },
      {
        id: 104,
        currencyId: 3,
        networkId: 1,
        name: 'Лимиты на вывод BTC',
        operationType: 'withdrawal',
        minAmount: '0.00001500',
        maxAmount: '0.00010000',
        createdAt: '2024-04-11T14:11:49.198766+02:00',
      },
      {
        id: 105,
        currencyId: 5,
        networkId: 3,
        name: 'Лимиты на депозит BNB BEP20',
        operationType: 'deposit',
        minAmount: '0.00020000',
        maxAmount: '0.00200000',
        createdAt: '2024-04-11T14:11:49.283317+02:00',
      },
      {
        id: 106,
        currencyId: 5,
        networkId: 3,
        name: 'Лимиты на вывод BNB BEP20',
        operationType: 'withdrawal',
        minAmount: '0.00002000',
        maxAmount: '0.00200000',
        createdAt: '2024-04-11T14:11:49.367172+02:00',
      },
      {
        id: 107,
        currencyId: 4,
        networkId: 2,
        name: 'Лимиты на депозит ETH Ethereum',
        operationType: 'deposit',
        minAmount: '0.00020000',
        maxAmount: '0.00100000',
        createdAt: '2024-04-11T14:11:49.459436+02:00',
      },
      {
        id: 108,
        currencyId: 4,
        networkId: 2,
        name: 'Лимиты на вывод ETH Ethereum',
        operationType: 'withdrawal',
        minAmount: '0.00020000',
        maxAmount: '0.00100000',
        createdAt: '2024-04-11T14:11:49.542087+02:00',
      },
      {
        id: 109,
        currencyId: 6,
        networkId: 5,
        name: 'Лимиты на депозит LTC Litecoin',
        operationType: 'deposit',
        minAmount: '0.01000000',
        maxAmount: '0.10000000',
        createdAt: '2024-04-11T14:11:49.626682+02:00',
      },
      {
        id: 110,
        currencyId: 6,
        networkId: 5,
        name: 'Лимиты на вывод LTC Litecoin',
        operationType: 'withdrawal',
        minAmount: '0.01000000',
        maxAmount: '0.10000000',
        createdAt: '2024-04-11T14:11:49.720239+02:00',
      },
      {
        id: 111,
        currencyId: 7,
        networkId: 4,
        name: 'Лимиты на депозит TRX Tron',
        operationType: 'deposit',
        minAmount: '0.50000000',
        maxAmount: '80.00000000',
        createdAt: '2024-04-11T14:11:49.799215+02:00',
      },
      {
        id: 128,
        currencyId: 7,
        networkId: 4,
        name: 'Лимиты на вывод TRX Tron',
        operationType: 'withdrawal',
        minAmount: '0.50000000',
        maxAmount: '999999999999.00000000',
        createdAt: '2024-05-22T13:32:35.280049+02:00',
      },
      {
        id: 97,
        currencyId: 1,
        networkId: 3,
        name: 'Лимиты на вывод USDT BEP20',
        operationType: 'withdrawal',
        minAmount: '0.01000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.587002+02:00',
      },
      {
        id: 96,
        currencyId: 1,
        networkId: 2,
        name: 'Лимиты на вывод USDT ERC20',
        operationType: 'withdrawal',
        minAmount: '0.01000000',
        maxAmount: '10.00000000',
        createdAt: '2024-04-11T14:11:48.489162+02:00',
      },
      {
        id: 145,
        currencyId: 2,
        networkId: 5,
        name: 'LW USDC Binance',
        operationType: 'withdrawal',
        minAmount: '10.00000000',
        maxAmount: '100.00000000',
        createdAt: '2024-07-12T11:47:49.689529+02:00',
      },
      {
        id: 146,
        currencyId: 2,
        networkId: 5,
        name: 'LW USDC Binance',
        operationType: 'withdrawal',
        minAmount: '30.00000000',
        maxAmount: '200.00000000',
        createdAt: '2024-07-12T11:47:49.721853+02:00',
      },
    ],
  }),
)

export const postBonusForDepositFind = stHttp.post(
  '/bonus-for-deposit/find',
  () =>
    HttpResponse.json({
      data: [
        {
          id: 1,
          createdAt: '2024-02-01T00:00:00',
          expiredAt: mockDateWithOffset({ hours: 1 }),
          minDepositAmountInAppCurrency: '99',
          maxDepositAmountInAppCurrency: '200',
          bonusSettings: {
            currencyId: 1,
            depositMultiplier: '100',
            rollingMultiplier: '1.0',
            rollingPeriodInHours: 1,
            rollingCasinoCategoryIds: [1, 2],
            rollingMinBetRate: '1.0',
            rollingMaxBetRate: '1.0',
            maxBonusAmountInBonusCurrency: '10000',
          },
          bonus: null,
        },
        {
          id: 2,
          createdAt: '2024-02-01T00:00:00',
          expiredAt: mockDateWithOffset({ days: 12 }),
          minDepositAmountInAppCurrency: '1.0',
          maxDepositAmountInAppCurrency: '2.0',
          bonusSettings: {
            currencyId: 1,
            depositMultiplier: '1.0',
            rollingMultiplier: '1.0',
            rollingPeriodInHours: 1,
            rollingCasinoCategoryIds: [1, 2],
            rollingMinBetRate: '1.0',
            rollingMaxBetRate: '1.0',
            maxBonusAmountInBonusCurrency: '10000',
          },
          bonus: {
            claimed: false,
            needRolling: true,
            rollingCompleted: false,
            id: 1,
            currencyId: 1,
            amountInBonusCurrency: '12.3123123123',
            expiredAt: '2024-02-01T00:00:00',
            currentRollingAmountInBonusCurrency: '8752.69',
            fullRollingAmountInBonusCurrency: '10850',
          },
        },
        {
          id: 3,
          createdAt: '2024-02-01T00:00:00',
          expiredAt: mockDateWithOffset({ hours: 1 }),
          minDepositAmountInAppCurrency: '1.0',
          maxDepositAmountInAppCurrency: '2.0',
          bonusSettings: {
            currencyId: 1,
            depositMultiplier: '1.0',
            rollingMultiplier: '1.0',
            rollingPeriodInHours: 1,
            rollingCasinoCategoryIds: [1, 2],
            rollingMinBetRate: '1.0',
            rollingMaxBetRate: '1.0',
            maxBonusAmountInBonusCurrency: '1.0',
          },
          bonus: {
            claimed: false,
            needRolling: true,
            rollingCompleted: true,
            id: 1,
            currencyId: 1,
            amountInBonusCurrency: '99123779.3812730981273173',
            expiredAt: '2024-02-01T00:00:00',
            currentRollingAmountInBonusCurrency: '1.0',
            fullRollingAmountInBonusCurrency: '1.0',
          },
        },
      ],
      paging: {
        page: 0,
        count: 0,
        total: 3,
      },
    }),
)

export const postWithdrawalCodeSend = stHttp.post(
  '/withdrawal/create/code/send',
  () =>
    HttpResponse.json(
      {
        error: 'TELEGRAM_SEND_MESSAGE_ERROR',
      },
      {
        status: 424,
      },
    ) as any,
)

export const postAddress = stHttp.post(
  '/payment-address/create/code/send',
  () =>
    HttpResponse.json(
      {
        error: 'TELEGRAM_SEND_MESSAGE_ERROR',
      },
      {
        status: 424,
      },
    ) as any,
)

export const postBonusSummaryInfo = stHttp.post('/bonus/summary/info', () =>
  HttpResponse.json([
    {
      currencyId: 1,
      totalAmount: '442',
    },
    {
      currencyId: 2,
      totalAmount: '123.12',
    },
    {
      currencyId: 3,
      totalAmount: '0.2233',
    },
    {
      currencyId: 5,
      totalAmount: '0',
    },
    {
      currencyId: 6,
      totalAmount: '0',
    },
    {
      currencyId: 9,
      totalAmount: '99.312',
    },
    {
      currencyId: 4,
      totalAmount: '0',
    },
    {
      currencyId: 8,
      totalAmount: '0',
    },
    {
      currencyId: 7,
      totalAmount: '0',
    },
  ]),
)

export default [
  // postCasinoGameInitError,
  // postCasinoGameInitRestriction,
  // postDepositCalypsoInit,
  // postDepositRollingDelta,
  // postWithdrawalCodeSend,
  // postAddress,
  postDepositAddressGet,
  postWelcomeBonus,
  postFreebetFind,
  postFreespinFind,
  postCashbackList,
  postReloadProgramList,
  postBonusFind,
  // postTariff,
  postBonusForDepositFind,
  postBonusSummaryInfo,
  // postGeoAssert,
  // getUserVerification,
  // geoAvailabilityAssert,
  // postAuthLoginCodeSend,
  // postRatRaces,
]
