import { default as indext1dZRm5SZjMeta } from "/app/apps/site/pages/about/index.vue?macro=true";
import { default as bonuses0IzCsU3UF1Meta } from "/app/apps/site/pages/bonuses.vue?macro=true";
import { default as _91code_93sWge7q6NidMeta } from "/app/apps/site/pages/casino/categories/[code].vue?macro=true";
import { default as _91id_93xzcd6sIDxDMeta } from "/app/apps/site/pages/casino/games/[id].vue?macro=true";
import { default as indexKxBHLCu100Meta } from "/app/apps/site/pages/casino/index.vue?macro=true";
import { default as _91provider_93OkNdbO6p7NMeta } from "/app/apps/site/pages/casino/providers/[provider].vue?macro=true";
import { default as indexm0lft7aPIQMeta } from "/app/apps/site/pages/casino/providers/index.vue?macro=true";
import { default as recentsRQI3XxdmiMeta } from "/app/apps/site/pages/casino/recent.vue?macro=true";
import { default as restrictionVCCrKWrabjMeta } from "/app/apps/site/pages/casino/restriction.vue?macro=true";
import { default as useCasinoPageNfngeVAFciMeta } from "/app/apps/site/pages/casino/useCasinoPage.ts?macro=true";
import { default as index6cqa2vd68hMeta } from "/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93i59KLwB3hxMeta } from "/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as index9pgS9eWsu8Meta } from "/app/apps/site/pages/cybersport/[categoryId]/index.vue?macro=true";
import { default as allOPxgJZtHQiMeta } from "/app/apps/site/pages/cybersport/all.vue?macro=true";
import { default as indexctsZvWhKmpMeta } from "/app/apps/site/pages/cybersport/index.vue?macro=true";
import { default as liveb1kkG9gATxMeta } from "/app/apps/site/pages/cybersport/live.vue?macro=true";
import { default as upcomingeAIdzvuhm8Meta } from "/app/apps/site/pages/cybersport/upcoming.vue?macro=true";
import { default as indexAGWIYPDswAMeta } from "/app/apps/site/pages/index.vue?macro=true";
import { default as bonusesD2RCjujVtjMeta } from "/app/apps/site/pages/mobile/bonuses.vue?macro=true";
import { default as _91code_93qnsYDUF5gsMeta } from "/app/apps/site/pages/mobile/casino/categories/[code].vue?macro=true";
import { default as _91id_93dCaJNoT8pCMeta } from "/app/apps/site/pages/mobile/casino/games/[id].vue?macro=true";
import { default as indexrTi5HvIA0YMeta } from "/app/apps/site/pages/mobile/casino/index.vue?macro=true";
import { default as _91provider_93mSnP4jjMB4Meta } from "/app/apps/site/pages/mobile/casino/providers/[provider].vue?macro=true";
import { default as indexoWW5sKcFaBMeta } from "/app/apps/site/pages/mobile/casino/providers/index.vue?macro=true";
import { default as recent70MF2fjFrMMeta } from "/app/apps/site/pages/mobile/casino/recent.vue?macro=true";
import { default as restrictionKDZxmvpctbMeta } from "/app/apps/site/pages/mobile/casino/restriction.vue?macro=true";
import { default as indexgWME7u8Q1IMeta } from "/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93DVt1Ud75wCMeta } from "/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as indexdH9LuxHdaqMeta } from "/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue?macro=true";
import { default as indexVUsbMRqukFMeta } from "/app/apps/site/pages/mobile/cybersport/index.vue?macro=true";
import { default as liverPDIk4ZKRrMeta } from "/app/apps/site/pages/mobile/cybersport/live.vue?macro=true";
import { default as upcomingl8twCvk0S4Meta } from "/app/apps/site/pages/mobile/cybersport/upcoming.vue?macro=true";
import { default as indexQetr5klI9HMeta } from "/app/apps/site/pages/mobile/index.vue?macro=true";
import { default as betsMTi5WP7IbTMeta } from "/app/apps/site/pages/mobile/personal/bets.vue?macro=true";
import { default as kychGo1dZz1ttMeta } from "/app/apps/site/pages/mobile/personal/kyc.vue?macro=true";
import { default as profile3vJcr8KiKGMeta } from "/app/apps/site/pages/mobile/personal/profile.vue?macro=true";
import { default as settingsYRoe6PG0BfMeta } from "/app/apps/site/pages/mobile/personal/settings.vue?macro=true";
import { default as transactionsAMF9N0beyOMeta } from "/app/apps/site/pages/mobile/personal/transactions.vue?macro=true";
import { default as walletXz5gW2EWqtMeta } from "/app/apps/site/pages/mobile/personal/wallet.vue?macro=true";
import { default as registrationrbzrfXjjhQMeta } from "/app/apps/site/pages/mobile/promo/registration.vue?macro=true";
import { default as refer_45and_45earnVwMEkgnOtnMeta } from "/app/apps/site/pages/mobile/refer-and-earn.vue?macro=true";
import { default as indexbhxh01Kd9wMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93hQrAdnwKrmMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as indexTYNAyURMIHMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue?macro=true";
import { default as indexpDhmRLHTMMMeta } from "/app/apps/site/pages/mobile/sport/[sportId]/index.vue?macro=true";
import { default as all5lh487dru4Meta } from "/app/apps/site/pages/mobile/sport/all.vue?macro=true";
import { default as indexqEDppdwYXwMeta } from "/app/apps/site/pages/mobile/sport/index.vue?macro=true";
import { default as liveGDPetqsNd2Meta } from "/app/apps/site/pages/mobile/sport/live.vue?macro=true";
import { default as upcomingjgGita4WfNMeta } from "/app/apps/site/pages/mobile/sport/upcoming.vue?macro=true";
import { default as supportamedNFbChdMeta } from "/app/apps/site/pages/mobile/support.vue?macro=true";
import { default as _91id_93uSojnDCrRoMeta } from "/app/apps/site/pages/mobile/tournaments/[id].vue?macro=true";
import { default as index2Rf6gY2o1DMeta } from "/app/apps/site/pages/mobile/tournaments/index.vue?macro=true";
import { default as unsubscribesnfj6WpwZ6Meta } from "/app/apps/site/pages/mobile/unsubscribe.vue?macro=true";
import { default as bets55PXI7Bq75Meta } from "/app/apps/site/pages/personal/bets.vue?macro=true";
import { default as kyc2QSbsoWkUkMeta } from "/app/apps/site/pages/personal/kyc.vue?macro=true";
import { default as profileGMGu9bdYmBMeta } from "/app/apps/site/pages/personal/profile.vue?macro=true";
import { default as transactionsxL2rxTO656Meta } from "/app/apps/site/pages/personal/transactions.vue?macro=true";
import { default as walletZ0QEOx9alnMeta } from "/app/apps/site/pages/personal/wallet.vue?macro=true";
import { default as registrationiZrL1sJ8iWMeta } from "/app/apps/site/pages/promo/registration.vue?macro=true";
import { default as refer_45and_45earnSrBsLbldv3Meta } from "/app/apps/site/pages/refer-and-earn.vue?macro=true";
import { default as indexKKlwbRssCTMeta } from "/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue?macro=true";
import { default as _91eventId_93UYUQdP4EI8Meta } from "/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue?macro=true";
import { default as indexZA8AreH4nrMeta } from "/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue?macro=true";
import { default as indexXqtKpNJIITMeta } from "/app/apps/site/pages/sport/[sportId]/index.vue?macro=true";
import { default as allvuzAUqv2gaMeta } from "/app/apps/site/pages/sport/all.vue?macro=true";
import { default as indexYaADUjozNSMeta } from "/app/apps/site/pages/sport/index.vue?macro=true";
import { default as liverNxADqpTpFMeta } from "/app/apps/site/pages/sport/live.vue?macro=true";
import { default as upcoming55fiukpALUMeta } from "/app/apps/site/pages/sport/upcoming.vue?macro=true";
import { default as supportRafuRYvRSeMeta } from "/app/apps/site/pages/support.vue?macro=true";
import { default as _91id_93tWYqDm8JarMeta } from "/app/apps/site/pages/tournaments/[id].vue?macro=true";
import { default as indexVwhhIVl36QMeta } from "/app/apps/site/pages/tournaments/index.vue?macro=true";
import { default as unsubscribe67u1rkBt9DMeta } from "/app/apps/site/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: indext1dZRm5SZjMeta?.name ?? "about___en",
    path: indext1dZRm5SZjMeta?.path ?? "/about",
    meta: indext1dZRm5SZjMeta || {},
    alias: indext1dZRm5SZjMeta?.alias || [],
    redirect: indext1dZRm5SZjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indext1dZRm5SZjMeta?.name ?? "about___ru",
    path: indext1dZRm5SZjMeta?.path ?? "/ru/about",
    meta: indext1dZRm5SZjMeta || {},
    alias: indext1dZRm5SZjMeta?.alias || [],
    redirect: indext1dZRm5SZjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indext1dZRm5SZjMeta?.name ?? "about___es",
    path: indext1dZRm5SZjMeta?.path ?? "/es/about",
    meta: indext1dZRm5SZjMeta || {},
    alias: indext1dZRm5SZjMeta?.alias || [],
    redirect: indext1dZRm5SZjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indext1dZRm5SZjMeta?.name ?? "about___pt",
    path: indext1dZRm5SZjMeta?.path ?? "/pt/about",
    meta: indext1dZRm5SZjMeta || {},
    alias: indext1dZRm5SZjMeta?.alias || [],
    redirect: indext1dZRm5SZjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: bonuses0IzCsU3UF1Meta?.name ?? "bonuses___en",
    path: bonuses0IzCsU3UF1Meta?.path ?? "/bonuses",
    meta: bonuses0IzCsU3UF1Meta || {},
    alias: bonuses0IzCsU3UF1Meta?.alias || [],
    redirect: bonuses0IzCsU3UF1Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/bonuses.vue").then(m => m.default || m)
  },
  {
    name: bonuses0IzCsU3UF1Meta?.name ?? "bonuses___ru",
    path: bonuses0IzCsU3UF1Meta?.path ?? "/ru/bonuses",
    meta: bonuses0IzCsU3UF1Meta || {},
    alias: bonuses0IzCsU3UF1Meta?.alias || [],
    redirect: bonuses0IzCsU3UF1Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/bonuses.vue").then(m => m.default || m)
  },
  {
    name: bonuses0IzCsU3UF1Meta?.name ?? "bonuses___es",
    path: bonuses0IzCsU3UF1Meta?.path ?? "/es/bonuses",
    meta: bonuses0IzCsU3UF1Meta || {},
    alias: bonuses0IzCsU3UF1Meta?.alias || [],
    redirect: bonuses0IzCsU3UF1Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/bonuses.vue").then(m => m.default || m)
  },
  {
    name: bonuses0IzCsU3UF1Meta?.name ?? "bonuses___pt",
    path: bonuses0IzCsU3UF1Meta?.path ?? "/pt/bonuses",
    meta: bonuses0IzCsU3UF1Meta || {},
    alias: bonuses0IzCsU3UF1Meta?.alias || [],
    redirect: bonuses0IzCsU3UF1Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/bonuses.vue").then(m => m.default || m)
  },
  {
    name: _91code_93sWge7q6NidMeta?.name ?? "casino-categories-code___en",
    path: _91code_93sWge7q6NidMeta?.path ?? "/casino/categories/:code()",
    meta: _91code_93sWge7q6NidMeta || {},
    alias: _91code_93sWge7q6NidMeta?.alias || [],
    redirect: _91code_93sWge7q6NidMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93sWge7q6NidMeta?.name ?? "casino-categories-code___ru",
    path: _91code_93sWge7q6NidMeta?.path ?? "/ru/casino/categories/:code()",
    meta: _91code_93sWge7q6NidMeta || {},
    alias: _91code_93sWge7q6NidMeta?.alias || [],
    redirect: _91code_93sWge7q6NidMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93sWge7q6NidMeta?.name ?? "casino-categories-code___es",
    path: _91code_93sWge7q6NidMeta?.path ?? "/es/casino/categories/:code()",
    meta: _91code_93sWge7q6NidMeta || {},
    alias: _91code_93sWge7q6NidMeta?.alias || [],
    redirect: _91code_93sWge7q6NidMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93sWge7q6NidMeta?.name ?? "casino-categories-code___pt",
    path: _91code_93sWge7q6NidMeta?.path ?? "/pt/casino/categories/:code()",
    meta: _91code_93sWge7q6NidMeta || {},
    alias: _91code_93sWge7q6NidMeta?.alias || [],
    redirect: _91code_93sWge7q6NidMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xzcd6sIDxDMeta?.name ?? "casino-games-id___en",
    path: _91id_93xzcd6sIDxDMeta?.path ?? "/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    alias: _91id_93xzcd6sIDxDMeta?.alias || [],
    redirect: _91id_93xzcd6sIDxDMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xzcd6sIDxDMeta?.name ?? "casino-games-id___ru",
    path: _91id_93xzcd6sIDxDMeta?.path ?? "/ru/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    alias: _91id_93xzcd6sIDxDMeta?.alias || [],
    redirect: _91id_93xzcd6sIDxDMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xzcd6sIDxDMeta?.name ?? "casino-games-id___es",
    path: _91id_93xzcd6sIDxDMeta?.path ?? "/es/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    alias: _91id_93xzcd6sIDxDMeta?.alias || [],
    redirect: _91id_93xzcd6sIDxDMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xzcd6sIDxDMeta?.name ?? "casino-games-id___pt",
    path: _91id_93xzcd6sIDxDMeta?.path ?? "/pt/casino/games/:id()",
    meta: _91id_93xzcd6sIDxDMeta || {},
    alias: _91id_93xzcd6sIDxDMeta?.alias || [],
    redirect: _91id_93xzcd6sIDxDMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKxBHLCu100Meta?.name ?? "casino___en",
    path: indexKxBHLCu100Meta?.path ?? "/casino",
    meta: indexKxBHLCu100Meta || {},
    alias: indexKxBHLCu100Meta?.alias || [],
    redirect: indexKxBHLCu100Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxBHLCu100Meta?.name ?? "casino___ru",
    path: indexKxBHLCu100Meta?.path ?? "/ru/casino",
    meta: indexKxBHLCu100Meta || {},
    alias: indexKxBHLCu100Meta?.alias || [],
    redirect: indexKxBHLCu100Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxBHLCu100Meta?.name ?? "casino___es",
    path: indexKxBHLCu100Meta?.path ?? "/es/casino",
    meta: indexKxBHLCu100Meta || {},
    alias: indexKxBHLCu100Meta?.alias || [],
    redirect: indexKxBHLCu100Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxBHLCu100Meta?.name ?? "casino___pt",
    path: indexKxBHLCu100Meta?.path ?? "/pt/casino",
    meta: indexKxBHLCu100Meta || {},
    alias: indexKxBHLCu100Meta?.alias || [],
    redirect: indexKxBHLCu100Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/index.vue").then(m => m.default || m)
  },
  {
    name: _91provider_93OkNdbO6p7NMeta?.name ?? "casino-providers-provider___en",
    path: _91provider_93OkNdbO6p7NMeta?.path ?? "/casino/providers/:provider()",
    meta: _91provider_93OkNdbO6p7NMeta || {},
    alias: _91provider_93OkNdbO6p7NMeta?.alias || [],
    redirect: _91provider_93OkNdbO6p7NMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93OkNdbO6p7NMeta?.name ?? "casino-providers-provider___ru",
    path: _91provider_93OkNdbO6p7NMeta?.path ?? "/ru/casino/providers/:provider()",
    meta: _91provider_93OkNdbO6p7NMeta || {},
    alias: _91provider_93OkNdbO6p7NMeta?.alias || [],
    redirect: _91provider_93OkNdbO6p7NMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93OkNdbO6p7NMeta?.name ?? "casino-providers-provider___es",
    path: _91provider_93OkNdbO6p7NMeta?.path ?? "/es/casino/providers/:provider()",
    meta: _91provider_93OkNdbO6p7NMeta || {},
    alias: _91provider_93OkNdbO6p7NMeta?.alias || [],
    redirect: _91provider_93OkNdbO6p7NMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93OkNdbO6p7NMeta?.name ?? "casino-providers-provider___pt",
    path: _91provider_93OkNdbO6p7NMeta?.path ?? "/pt/casino/providers/:provider()",
    meta: _91provider_93OkNdbO6p7NMeta || {},
    alias: _91provider_93OkNdbO6p7NMeta?.alias || [],
    redirect: _91provider_93OkNdbO6p7NMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: indexm0lft7aPIQMeta?.name ?? "casino-providers___en",
    path: indexm0lft7aPIQMeta?.path ?? "/casino/providers",
    meta: indexm0lft7aPIQMeta || {},
    alias: indexm0lft7aPIQMeta?.alias || [],
    redirect: indexm0lft7aPIQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0lft7aPIQMeta?.name ?? "casino-providers___ru",
    path: indexm0lft7aPIQMeta?.path ?? "/ru/casino/providers",
    meta: indexm0lft7aPIQMeta || {},
    alias: indexm0lft7aPIQMeta?.alias || [],
    redirect: indexm0lft7aPIQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0lft7aPIQMeta?.name ?? "casino-providers___es",
    path: indexm0lft7aPIQMeta?.path ?? "/es/casino/providers",
    meta: indexm0lft7aPIQMeta || {},
    alias: indexm0lft7aPIQMeta?.alias || [],
    redirect: indexm0lft7aPIQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexm0lft7aPIQMeta?.name ?? "casino-providers___pt",
    path: indexm0lft7aPIQMeta?.path ?? "/pt/casino/providers",
    meta: indexm0lft7aPIQMeta || {},
    alias: indexm0lft7aPIQMeta?.alias || [],
    redirect: indexm0lft7aPIQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: recentsRQI3XxdmiMeta?.name ?? "casino-recent___en",
    path: recentsRQI3XxdmiMeta?.path ?? "/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    alias: recentsRQI3XxdmiMeta?.alias || [],
    redirect: recentsRQI3XxdmiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: recentsRQI3XxdmiMeta?.name ?? "casino-recent___ru",
    path: recentsRQI3XxdmiMeta?.path ?? "/ru/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    alias: recentsRQI3XxdmiMeta?.alias || [],
    redirect: recentsRQI3XxdmiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: recentsRQI3XxdmiMeta?.name ?? "casino-recent___es",
    path: recentsRQI3XxdmiMeta?.path ?? "/es/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    alias: recentsRQI3XxdmiMeta?.alias || [],
    redirect: recentsRQI3XxdmiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: recentsRQI3XxdmiMeta?.name ?? "casino-recent___pt",
    path: recentsRQI3XxdmiMeta?.path ?? "/pt/casino/recent",
    meta: recentsRQI3XxdmiMeta || {},
    alias: recentsRQI3XxdmiMeta?.alias || [],
    redirect: recentsRQI3XxdmiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: restrictionVCCrKWrabjMeta?.name ?? "casino-restriction___en",
    path: restrictionVCCrKWrabjMeta?.path ?? "/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    alias: restrictionVCCrKWrabjMeta?.alias || [],
    redirect: restrictionVCCrKWrabjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: restrictionVCCrKWrabjMeta?.name ?? "casino-restriction___ru",
    path: restrictionVCCrKWrabjMeta?.path ?? "/ru/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    alias: restrictionVCCrKWrabjMeta?.alias || [],
    redirect: restrictionVCCrKWrabjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: restrictionVCCrKWrabjMeta?.name ?? "casino-restriction___es",
    path: restrictionVCCrKWrabjMeta?.path ?? "/es/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    alias: restrictionVCCrKWrabjMeta?.alias || [],
    redirect: restrictionVCCrKWrabjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: restrictionVCCrKWrabjMeta?.name ?? "casino-restriction___pt",
    path: restrictionVCCrKWrabjMeta?.path ?? "/pt/casino/restriction",
    meta: restrictionVCCrKWrabjMeta || {},
    alias: restrictionVCCrKWrabjMeta?.alias || [],
    redirect: restrictionVCCrKWrabjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: useCasinoPageNfngeVAFciMeta?.name ?? "casino-useCasinoPage___en",
    path: useCasinoPageNfngeVAFciMeta?.path ?? "/casino/useCasinoPage",
    meta: useCasinoPageNfngeVAFciMeta || {},
    alias: useCasinoPageNfngeVAFciMeta?.alias || [],
    redirect: useCasinoPageNfngeVAFciMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/useCasinoPage.ts").then(m => m.default || m)
  },
  {
    name: useCasinoPageNfngeVAFciMeta?.name ?? "casino-useCasinoPage___ru",
    path: useCasinoPageNfngeVAFciMeta?.path ?? "/ru/casino/useCasinoPage",
    meta: useCasinoPageNfngeVAFciMeta || {},
    alias: useCasinoPageNfngeVAFciMeta?.alias || [],
    redirect: useCasinoPageNfngeVAFciMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/useCasinoPage.ts").then(m => m.default || m)
  },
  {
    name: useCasinoPageNfngeVAFciMeta?.name ?? "casino-useCasinoPage___es",
    path: useCasinoPageNfngeVAFciMeta?.path ?? "/es/casino/useCasinoPage",
    meta: useCasinoPageNfngeVAFciMeta || {},
    alias: useCasinoPageNfngeVAFciMeta?.alias || [],
    redirect: useCasinoPageNfngeVAFciMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/useCasinoPage.ts").then(m => m.default || m)
  },
  {
    name: useCasinoPageNfngeVAFciMeta?.name ?? "casino-useCasinoPage___pt",
    path: useCasinoPageNfngeVAFciMeta?.path ?? "/pt/casino/useCasinoPage",
    meta: useCasinoPageNfngeVAFciMeta || {},
    alias: useCasinoPageNfngeVAFciMeta?.alias || [],
    redirect: useCasinoPageNfngeVAFciMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/casino/useCasinoPage.ts").then(m => m.default || m)
  },
  {
    name: index6cqa2vd68hMeta?.name ?? "cybersport-categoryId-tournamentId___en",
    path: index6cqa2vd68hMeta?.path ?? "/cybersport/:categoryId()/:tournamentId()",
    meta: index6cqa2vd68hMeta || {},
    alias: index6cqa2vd68hMeta?.alias || [],
    redirect: index6cqa2vd68hMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: index6cqa2vd68hMeta?.name ?? "cybersport-categoryId-tournamentId___ru",
    path: index6cqa2vd68hMeta?.path ?? "/ru/cybersport/:categoryId()/:tournamentId()",
    meta: index6cqa2vd68hMeta || {},
    alias: index6cqa2vd68hMeta?.alias || [],
    redirect: index6cqa2vd68hMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: index6cqa2vd68hMeta?.name ?? "cybersport-categoryId-tournamentId___es",
    path: index6cqa2vd68hMeta?.path ?? "/es/cybersport/:categoryId()/:tournamentId()",
    meta: index6cqa2vd68hMeta || {},
    alias: index6cqa2vd68hMeta?.alias || [],
    redirect: index6cqa2vd68hMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: index6cqa2vd68hMeta?.name ?? "cybersport-categoryId-tournamentId___pt",
    path: index6cqa2vd68hMeta?.path ?? "/pt/cybersport/:categoryId()/:tournamentId()",
    meta: index6cqa2vd68hMeta || {},
    alias: index6cqa2vd68hMeta?.alias || [],
    redirect: index6cqa2vd68hMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93i59KLwB3hxMeta?.name ?? "cybersport-categoryId-tournamentId-match-eventId___en",
    path: _91eventId_93i59KLwB3hxMeta?.path ?? "/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    alias: _91eventId_93i59KLwB3hxMeta?.alias || [],
    redirect: _91eventId_93i59KLwB3hxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93i59KLwB3hxMeta?.name ?? "cybersport-categoryId-tournamentId-match-eventId___ru",
    path: _91eventId_93i59KLwB3hxMeta?.path ?? "/ru/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    alias: _91eventId_93i59KLwB3hxMeta?.alias || [],
    redirect: _91eventId_93i59KLwB3hxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93i59KLwB3hxMeta?.name ?? "cybersport-categoryId-tournamentId-match-eventId___es",
    path: _91eventId_93i59KLwB3hxMeta?.path ?? "/es/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    alias: _91eventId_93i59KLwB3hxMeta?.alias || [],
    redirect: _91eventId_93i59KLwB3hxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93i59KLwB3hxMeta?.name ?? "cybersport-categoryId-tournamentId-match-eventId___pt",
    path: _91eventId_93i59KLwB3hxMeta?.path ?? "/pt/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93i59KLwB3hxMeta || {},
    alias: _91eventId_93i59KLwB3hxMeta?.alias || [],
    redirect: _91eventId_93i59KLwB3hxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: index9pgS9eWsu8Meta?.name ?? "cybersport-categoryId___en",
    path: index9pgS9eWsu8Meta?.path ?? "/cybersport/:categoryId()",
    meta: index9pgS9eWsu8Meta || {},
    alias: index9pgS9eWsu8Meta?.alias || [],
    redirect: index9pgS9eWsu8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9pgS9eWsu8Meta?.name ?? "cybersport-categoryId___ru",
    path: index9pgS9eWsu8Meta?.path ?? "/ru/cybersport/:categoryId()",
    meta: index9pgS9eWsu8Meta || {},
    alias: index9pgS9eWsu8Meta?.alias || [],
    redirect: index9pgS9eWsu8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9pgS9eWsu8Meta?.name ?? "cybersport-categoryId___es",
    path: index9pgS9eWsu8Meta?.path ?? "/es/cybersport/:categoryId()",
    meta: index9pgS9eWsu8Meta || {},
    alias: index9pgS9eWsu8Meta?.alias || [],
    redirect: index9pgS9eWsu8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: index9pgS9eWsu8Meta?.name ?? "cybersport-categoryId___pt",
    path: index9pgS9eWsu8Meta?.path ?? "/pt/cybersport/:categoryId()",
    meta: index9pgS9eWsu8Meta || {},
    alias: index9pgS9eWsu8Meta?.alias || [],
    redirect: index9pgS9eWsu8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: allOPxgJZtHQiMeta?.name ?? "cybersport-all___en",
    path: allOPxgJZtHQiMeta?.path ?? "/cybersport/all",
    meta: allOPxgJZtHQiMeta || {},
    alias: allOPxgJZtHQiMeta?.alias || [],
    redirect: allOPxgJZtHQiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/all.vue").then(m => m.default || m)
  },
  {
    name: allOPxgJZtHQiMeta?.name ?? "cybersport-all___ru",
    path: allOPxgJZtHQiMeta?.path ?? "/ru/cybersport/all",
    meta: allOPxgJZtHQiMeta || {},
    alias: allOPxgJZtHQiMeta?.alias || [],
    redirect: allOPxgJZtHQiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/all.vue").then(m => m.default || m)
  },
  {
    name: allOPxgJZtHQiMeta?.name ?? "cybersport-all___es",
    path: allOPxgJZtHQiMeta?.path ?? "/es/cybersport/all",
    meta: allOPxgJZtHQiMeta || {},
    alias: allOPxgJZtHQiMeta?.alias || [],
    redirect: allOPxgJZtHQiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/all.vue").then(m => m.default || m)
  },
  {
    name: allOPxgJZtHQiMeta?.name ?? "cybersport-all___pt",
    path: allOPxgJZtHQiMeta?.path ?? "/pt/cybersport/all",
    meta: allOPxgJZtHQiMeta || {},
    alias: allOPxgJZtHQiMeta?.alias || [],
    redirect: allOPxgJZtHQiMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/all.vue").then(m => m.default || m)
  },
  {
    name: indexctsZvWhKmpMeta?.name ?? "cybersport___en",
    path: indexctsZvWhKmpMeta?.path ?? "/cybersport",
    meta: indexctsZvWhKmpMeta || {},
    alias: indexctsZvWhKmpMeta?.alias || [],
    redirect: indexctsZvWhKmpMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: indexctsZvWhKmpMeta?.name ?? "cybersport___ru",
    path: indexctsZvWhKmpMeta?.path ?? "/ru/cybersport",
    meta: indexctsZvWhKmpMeta || {},
    alias: indexctsZvWhKmpMeta?.alias || [],
    redirect: indexctsZvWhKmpMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: indexctsZvWhKmpMeta?.name ?? "cybersport___es",
    path: indexctsZvWhKmpMeta?.path ?? "/es/cybersport",
    meta: indexctsZvWhKmpMeta || {},
    alias: indexctsZvWhKmpMeta?.alias || [],
    redirect: indexctsZvWhKmpMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: indexctsZvWhKmpMeta?.name ?? "cybersport___pt",
    path: indexctsZvWhKmpMeta?.path ?? "/pt/cybersport",
    meta: indexctsZvWhKmpMeta || {},
    alias: indexctsZvWhKmpMeta?.alias || [],
    redirect: indexctsZvWhKmpMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: liveb1kkG9gATxMeta?.name ?? "cybersport-live___en",
    path: liveb1kkG9gATxMeta?.path ?? "/cybersport/live",
    meta: liveb1kkG9gATxMeta || {},
    alias: liveb1kkG9gATxMeta?.alias || [],
    redirect: liveb1kkG9gATxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: liveb1kkG9gATxMeta?.name ?? "cybersport-live___ru",
    path: liveb1kkG9gATxMeta?.path ?? "/ru/cybersport/live",
    meta: liveb1kkG9gATxMeta || {},
    alias: liveb1kkG9gATxMeta?.alias || [],
    redirect: liveb1kkG9gATxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: liveb1kkG9gATxMeta?.name ?? "cybersport-live___es",
    path: liveb1kkG9gATxMeta?.path ?? "/es/cybersport/live",
    meta: liveb1kkG9gATxMeta || {},
    alias: liveb1kkG9gATxMeta?.alias || [],
    redirect: liveb1kkG9gATxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: liveb1kkG9gATxMeta?.name ?? "cybersport-live___pt",
    path: liveb1kkG9gATxMeta?.path ?? "/pt/cybersport/live",
    meta: liveb1kkG9gATxMeta || {},
    alias: liveb1kkG9gATxMeta?.alias || [],
    redirect: liveb1kkG9gATxMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: upcomingeAIdzvuhm8Meta?.name ?? "cybersport-upcoming___en",
    path: upcomingeAIdzvuhm8Meta?.path ?? "/cybersport/upcoming",
    meta: upcomingeAIdzvuhm8Meta || {},
    alias: upcomingeAIdzvuhm8Meta?.alias || [],
    redirect: upcomingeAIdzvuhm8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingeAIdzvuhm8Meta?.name ?? "cybersport-upcoming___ru",
    path: upcomingeAIdzvuhm8Meta?.path ?? "/ru/cybersport/upcoming",
    meta: upcomingeAIdzvuhm8Meta || {},
    alias: upcomingeAIdzvuhm8Meta?.alias || [],
    redirect: upcomingeAIdzvuhm8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingeAIdzvuhm8Meta?.name ?? "cybersport-upcoming___es",
    path: upcomingeAIdzvuhm8Meta?.path ?? "/es/cybersport/upcoming",
    meta: upcomingeAIdzvuhm8Meta || {},
    alias: upcomingeAIdzvuhm8Meta?.alias || [],
    redirect: upcomingeAIdzvuhm8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingeAIdzvuhm8Meta?.name ?? "cybersport-upcoming___pt",
    path: upcomingeAIdzvuhm8Meta?.path ?? "/pt/cybersport/upcoming",
    meta: upcomingeAIdzvuhm8Meta || {},
    alias: upcomingeAIdzvuhm8Meta?.alias || [],
    redirect: upcomingeAIdzvuhm8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: indexAGWIYPDswAMeta?.name ?? "index___en",
    path: indexAGWIYPDswAMeta?.path ?? "/",
    meta: indexAGWIYPDswAMeta || {},
    alias: indexAGWIYPDswAMeta?.alias || [],
    redirect: indexAGWIYPDswAMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAGWIYPDswAMeta?.name ?? "index___ru",
    path: indexAGWIYPDswAMeta?.path ?? "/ru",
    meta: indexAGWIYPDswAMeta || {},
    alias: indexAGWIYPDswAMeta?.alias || [],
    redirect: indexAGWIYPDswAMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAGWIYPDswAMeta?.name ?? "index___es",
    path: indexAGWIYPDswAMeta?.path ?? "/es",
    meta: indexAGWIYPDswAMeta || {},
    alias: indexAGWIYPDswAMeta?.alias || [],
    redirect: indexAGWIYPDswAMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAGWIYPDswAMeta?.name ?? "index___pt",
    path: indexAGWIYPDswAMeta?.path ?? "/pt",
    meta: indexAGWIYPDswAMeta || {},
    alias: indexAGWIYPDswAMeta?.alias || [],
    redirect: indexAGWIYPDswAMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: bonusesD2RCjujVtjMeta?.name ?? "mobile-bonuses___en",
    path: bonusesD2RCjujVtjMeta?.path ?? "/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    alias: bonusesD2RCjujVtjMeta?.alias || [],
    redirect: bonusesD2RCjujVtjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: bonusesD2RCjujVtjMeta?.name ?? "mobile-bonuses___ru",
    path: bonusesD2RCjujVtjMeta?.path ?? "/ru/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    alias: bonusesD2RCjujVtjMeta?.alias || [],
    redirect: bonusesD2RCjujVtjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: bonusesD2RCjujVtjMeta?.name ?? "mobile-bonuses___es",
    path: bonusesD2RCjujVtjMeta?.path ?? "/es/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    alias: bonusesD2RCjujVtjMeta?.alias || [],
    redirect: bonusesD2RCjujVtjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: bonusesD2RCjujVtjMeta?.name ?? "mobile-bonuses___pt",
    path: bonusesD2RCjujVtjMeta?.path ?? "/pt/mobile/bonuses",
    meta: bonusesD2RCjujVtjMeta || {},
    alias: bonusesD2RCjujVtjMeta?.alias || [],
    redirect: bonusesD2RCjujVtjMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/bonuses.vue").then(m => m.default || m)
  },
  {
    name: _91code_93qnsYDUF5gsMeta?.name ?? "mobile-casino-categories-code___en",
    path: _91code_93qnsYDUF5gsMeta?.path ?? "/mobile/casino/categories/:code()",
    meta: _91code_93qnsYDUF5gsMeta || {},
    alias: _91code_93qnsYDUF5gsMeta?.alias || [],
    redirect: _91code_93qnsYDUF5gsMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93qnsYDUF5gsMeta?.name ?? "mobile-casino-categories-code___ru",
    path: _91code_93qnsYDUF5gsMeta?.path ?? "/ru/mobile/casino/categories/:code()",
    meta: _91code_93qnsYDUF5gsMeta || {},
    alias: _91code_93qnsYDUF5gsMeta?.alias || [],
    redirect: _91code_93qnsYDUF5gsMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93qnsYDUF5gsMeta?.name ?? "mobile-casino-categories-code___es",
    path: _91code_93qnsYDUF5gsMeta?.path ?? "/es/mobile/casino/categories/:code()",
    meta: _91code_93qnsYDUF5gsMeta || {},
    alias: _91code_93qnsYDUF5gsMeta?.alias || [],
    redirect: _91code_93qnsYDUF5gsMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93qnsYDUF5gsMeta?.name ?? "mobile-casino-categories-code___pt",
    path: _91code_93qnsYDUF5gsMeta?.path ?? "/pt/mobile/casino/categories/:code()",
    meta: _91code_93qnsYDUF5gsMeta || {},
    alias: _91code_93qnsYDUF5gsMeta?.alias || [],
    redirect: _91code_93qnsYDUF5gsMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/categories/[code].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dCaJNoT8pCMeta?.name ?? "mobile-casino-games-id___en",
    path: _91id_93dCaJNoT8pCMeta?.path ?? "/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    alias: _91id_93dCaJNoT8pCMeta?.alias || [],
    redirect: _91id_93dCaJNoT8pCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dCaJNoT8pCMeta?.name ?? "mobile-casino-games-id___ru",
    path: _91id_93dCaJNoT8pCMeta?.path ?? "/ru/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    alias: _91id_93dCaJNoT8pCMeta?.alias || [],
    redirect: _91id_93dCaJNoT8pCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dCaJNoT8pCMeta?.name ?? "mobile-casino-games-id___es",
    path: _91id_93dCaJNoT8pCMeta?.path ?? "/es/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    alias: _91id_93dCaJNoT8pCMeta?.alias || [],
    redirect: _91id_93dCaJNoT8pCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93dCaJNoT8pCMeta?.name ?? "mobile-casino-games-id___pt",
    path: _91id_93dCaJNoT8pCMeta?.path ?? "/pt/mobile/casino/games/:id()",
    meta: _91id_93dCaJNoT8pCMeta || {},
    alias: _91id_93dCaJNoT8pCMeta?.alias || [],
    redirect: _91id_93dCaJNoT8pCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/games/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrTi5HvIA0YMeta?.name ?? "mobile-casino___en",
    path: indexrTi5HvIA0YMeta?.path ?? "/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    alias: indexrTi5HvIA0YMeta?.alias || [],
    redirect: indexrTi5HvIA0YMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue").then(m => m.default || m)
  },
  {
    name: indexrTi5HvIA0YMeta?.name ?? "mobile-casino___ru",
    path: indexrTi5HvIA0YMeta?.path ?? "/ru/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    alias: indexrTi5HvIA0YMeta?.alias || [],
    redirect: indexrTi5HvIA0YMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue").then(m => m.default || m)
  },
  {
    name: indexrTi5HvIA0YMeta?.name ?? "mobile-casino___es",
    path: indexrTi5HvIA0YMeta?.path ?? "/es/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    alias: indexrTi5HvIA0YMeta?.alias || [],
    redirect: indexrTi5HvIA0YMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue").then(m => m.default || m)
  },
  {
    name: indexrTi5HvIA0YMeta?.name ?? "mobile-casino___pt",
    path: indexrTi5HvIA0YMeta?.path ?? "/pt/mobile/casino",
    meta: indexrTi5HvIA0YMeta || {},
    alias: indexrTi5HvIA0YMeta?.alias || [],
    redirect: indexrTi5HvIA0YMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/index.vue").then(m => m.default || m)
  },
  {
    name: _91provider_93mSnP4jjMB4Meta?.name ?? "mobile-casino-providers-provider___en",
    path: _91provider_93mSnP4jjMB4Meta?.path ?? "/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    alias: _91provider_93mSnP4jjMB4Meta?.alias || [],
    redirect: _91provider_93mSnP4jjMB4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93mSnP4jjMB4Meta?.name ?? "mobile-casino-providers-provider___ru",
    path: _91provider_93mSnP4jjMB4Meta?.path ?? "/ru/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    alias: _91provider_93mSnP4jjMB4Meta?.alias || [],
    redirect: _91provider_93mSnP4jjMB4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93mSnP4jjMB4Meta?.name ?? "mobile-casino-providers-provider___es",
    path: _91provider_93mSnP4jjMB4Meta?.path ?? "/es/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    alias: _91provider_93mSnP4jjMB4Meta?.alias || [],
    redirect: _91provider_93mSnP4jjMB4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: _91provider_93mSnP4jjMB4Meta?.name ?? "mobile-casino-providers-provider___pt",
    path: _91provider_93mSnP4jjMB4Meta?.path ?? "/pt/mobile/casino/providers/:provider()",
    meta: _91provider_93mSnP4jjMB4Meta || {},
    alias: _91provider_93mSnP4jjMB4Meta?.alias || [],
    redirect: _91provider_93mSnP4jjMB4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/[provider].vue").then(m => m.default || m)
  },
  {
    name: indexoWW5sKcFaBMeta?.name ?? "mobile-casino-providers___en",
    path: indexoWW5sKcFaBMeta?.path ?? "/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    alias: indexoWW5sKcFaBMeta?.alias || [],
    redirect: indexoWW5sKcFaBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexoWW5sKcFaBMeta?.name ?? "mobile-casino-providers___ru",
    path: indexoWW5sKcFaBMeta?.path ?? "/ru/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    alias: indexoWW5sKcFaBMeta?.alias || [],
    redirect: indexoWW5sKcFaBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexoWW5sKcFaBMeta?.name ?? "mobile-casino-providers___es",
    path: indexoWW5sKcFaBMeta?.path ?? "/es/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    alias: indexoWW5sKcFaBMeta?.alias || [],
    redirect: indexoWW5sKcFaBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: indexoWW5sKcFaBMeta?.name ?? "mobile-casino-providers___pt",
    path: indexoWW5sKcFaBMeta?.path ?? "/pt/mobile/casino/providers",
    meta: indexoWW5sKcFaBMeta || {},
    alias: indexoWW5sKcFaBMeta?.alias || [],
    redirect: indexoWW5sKcFaBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/providers/index.vue").then(m => m.default || m)
  },
  {
    name: recent70MF2fjFrMMeta?.name ?? "mobile-casino-recent___en",
    path: recent70MF2fjFrMMeta?.path ?? "/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    alias: recent70MF2fjFrMMeta?.alias || [],
    redirect: recent70MF2fjFrMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: recent70MF2fjFrMMeta?.name ?? "mobile-casino-recent___ru",
    path: recent70MF2fjFrMMeta?.path ?? "/ru/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    alias: recent70MF2fjFrMMeta?.alias || [],
    redirect: recent70MF2fjFrMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: recent70MF2fjFrMMeta?.name ?? "mobile-casino-recent___es",
    path: recent70MF2fjFrMMeta?.path ?? "/es/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    alias: recent70MF2fjFrMMeta?.alias || [],
    redirect: recent70MF2fjFrMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: recent70MF2fjFrMMeta?.name ?? "mobile-casino-recent___pt",
    path: recent70MF2fjFrMMeta?.path ?? "/pt/mobile/casino/recent",
    meta: recent70MF2fjFrMMeta || {},
    alias: recent70MF2fjFrMMeta?.alias || [],
    redirect: recent70MF2fjFrMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/recent.vue").then(m => m.default || m)
  },
  {
    name: restrictionKDZxmvpctbMeta?.name ?? "mobile-casino-restriction___en",
    path: restrictionKDZxmvpctbMeta?.path ?? "/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    alias: restrictionKDZxmvpctbMeta?.alias || [],
    redirect: restrictionKDZxmvpctbMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: restrictionKDZxmvpctbMeta?.name ?? "mobile-casino-restriction___ru",
    path: restrictionKDZxmvpctbMeta?.path ?? "/ru/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    alias: restrictionKDZxmvpctbMeta?.alias || [],
    redirect: restrictionKDZxmvpctbMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: restrictionKDZxmvpctbMeta?.name ?? "mobile-casino-restriction___es",
    path: restrictionKDZxmvpctbMeta?.path ?? "/es/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    alias: restrictionKDZxmvpctbMeta?.alias || [],
    redirect: restrictionKDZxmvpctbMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: restrictionKDZxmvpctbMeta?.name ?? "mobile-casino-restriction___pt",
    path: restrictionKDZxmvpctbMeta?.path ?? "/pt/mobile/casino/restriction",
    meta: restrictionKDZxmvpctbMeta || {},
    alias: restrictionKDZxmvpctbMeta?.alias || [],
    redirect: restrictionKDZxmvpctbMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/casino/restriction.vue").then(m => m.default || m)
  },
  {
    name: indexgWME7u8Q1IMeta?.name ?? "mobile-cybersport-categoryId-tournamentId___en",
    path: indexgWME7u8Q1IMeta?.path ?? "/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    alias: indexgWME7u8Q1IMeta?.alias || [],
    redirect: indexgWME7u8Q1IMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWME7u8Q1IMeta?.name ?? "mobile-cybersport-categoryId-tournamentId___ru",
    path: indexgWME7u8Q1IMeta?.path ?? "/ru/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    alias: indexgWME7u8Q1IMeta?.alias || [],
    redirect: indexgWME7u8Q1IMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWME7u8Q1IMeta?.name ?? "mobile-cybersport-categoryId-tournamentId___es",
    path: indexgWME7u8Q1IMeta?.path ?? "/es/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    alias: indexgWME7u8Q1IMeta?.alias || [],
    redirect: indexgWME7u8Q1IMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWME7u8Q1IMeta?.name ?? "mobile-cybersport-categoryId-tournamentId___pt",
    path: indexgWME7u8Q1IMeta?.path ?? "/pt/mobile/cybersport/:categoryId()/:tournamentId()",
    meta: indexgWME7u8Q1IMeta || {},
    alias: indexgWME7u8Q1IMeta?.alias || [],
    redirect: indexgWME7u8Q1IMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93DVt1Ud75wCMeta?.name ?? "mobile-cybersport-categoryId-tournamentId-match-eventId___en",
    path: _91eventId_93DVt1Ud75wCMeta?.path ?? "/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    alias: _91eventId_93DVt1Ud75wCMeta?.alias || [],
    redirect: _91eventId_93DVt1Ud75wCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93DVt1Ud75wCMeta?.name ?? "mobile-cybersport-categoryId-tournamentId-match-eventId___ru",
    path: _91eventId_93DVt1Ud75wCMeta?.path ?? "/ru/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    alias: _91eventId_93DVt1Ud75wCMeta?.alias || [],
    redirect: _91eventId_93DVt1Ud75wCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93DVt1Ud75wCMeta?.name ?? "mobile-cybersport-categoryId-tournamentId-match-eventId___es",
    path: _91eventId_93DVt1Ud75wCMeta?.path ?? "/es/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    alias: _91eventId_93DVt1Ud75wCMeta?.alias || [],
    redirect: _91eventId_93DVt1Ud75wCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93DVt1Ud75wCMeta?.name ?? "mobile-cybersport-categoryId-tournamentId-match-eventId___pt",
    path: _91eventId_93DVt1Ud75wCMeta?.path ?? "/pt/mobile/cybersport/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93DVt1Ud75wCMeta || {},
    alias: _91eventId_93DVt1Ud75wCMeta?.alias || [],
    redirect: _91eventId_93DVt1Ud75wCMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: indexdH9LuxHdaqMeta?.name ?? "mobile-cybersport-categoryId___en",
    path: indexdH9LuxHdaqMeta?.path ?? "/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    alias: indexdH9LuxHdaqMeta?.alias || [],
    redirect: indexdH9LuxHdaqMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdH9LuxHdaqMeta?.name ?? "mobile-cybersport-categoryId___ru",
    path: indexdH9LuxHdaqMeta?.path ?? "/ru/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    alias: indexdH9LuxHdaqMeta?.alias || [],
    redirect: indexdH9LuxHdaqMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdH9LuxHdaqMeta?.name ?? "mobile-cybersport-categoryId___es",
    path: indexdH9LuxHdaqMeta?.path ?? "/es/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    alias: indexdH9LuxHdaqMeta?.alias || [],
    redirect: indexdH9LuxHdaqMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdH9LuxHdaqMeta?.name ?? "mobile-cybersport-categoryId___pt",
    path: indexdH9LuxHdaqMeta?.path ?? "/pt/mobile/cybersport/:categoryId()",
    meta: indexdH9LuxHdaqMeta || {},
    alias: indexdH9LuxHdaqMeta?.alias || [],
    redirect: indexdH9LuxHdaqMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVUsbMRqukFMeta?.name ?? "mobile-cybersport___en",
    path: indexVUsbMRqukFMeta?.path ?? "/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    alias: indexVUsbMRqukFMeta?.alias || [],
    redirect: indexVUsbMRqukFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: indexVUsbMRqukFMeta?.name ?? "mobile-cybersport___ru",
    path: indexVUsbMRqukFMeta?.path ?? "/ru/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    alias: indexVUsbMRqukFMeta?.alias || [],
    redirect: indexVUsbMRqukFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: indexVUsbMRqukFMeta?.name ?? "mobile-cybersport___es",
    path: indexVUsbMRqukFMeta?.path ?? "/es/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    alias: indexVUsbMRqukFMeta?.alias || [],
    redirect: indexVUsbMRqukFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: indexVUsbMRqukFMeta?.name ?? "mobile-cybersport___pt",
    path: indexVUsbMRqukFMeta?.path ?? "/pt/mobile/cybersport",
    meta: indexVUsbMRqukFMeta || {},
    alias: indexVUsbMRqukFMeta?.alias || [],
    redirect: indexVUsbMRqukFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/index.vue").then(m => m.default || m)
  },
  {
    name: liverPDIk4ZKRrMeta?.name ?? "mobile-cybersport-live___en",
    path: liverPDIk4ZKRrMeta?.path ?? "/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    alias: liverPDIk4ZKRrMeta?.alias || [],
    redirect: liverPDIk4ZKRrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: liverPDIk4ZKRrMeta?.name ?? "mobile-cybersport-live___ru",
    path: liverPDIk4ZKRrMeta?.path ?? "/ru/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    alias: liverPDIk4ZKRrMeta?.alias || [],
    redirect: liverPDIk4ZKRrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: liverPDIk4ZKRrMeta?.name ?? "mobile-cybersport-live___es",
    path: liverPDIk4ZKRrMeta?.path ?? "/es/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    alias: liverPDIk4ZKRrMeta?.alias || [],
    redirect: liverPDIk4ZKRrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: liverPDIk4ZKRrMeta?.name ?? "mobile-cybersport-live___pt",
    path: liverPDIk4ZKRrMeta?.path ?? "/pt/mobile/cybersport/live",
    meta: liverPDIk4ZKRrMeta || {},
    alias: liverPDIk4ZKRrMeta?.alias || [],
    redirect: liverPDIk4ZKRrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/live.vue").then(m => m.default || m)
  },
  {
    name: upcomingl8twCvk0S4Meta?.name ?? "mobile-cybersport-upcoming___en",
    path: upcomingl8twCvk0S4Meta?.path ?? "/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    alias: upcomingl8twCvk0S4Meta?.alias || [],
    redirect: upcomingl8twCvk0S4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingl8twCvk0S4Meta?.name ?? "mobile-cybersport-upcoming___ru",
    path: upcomingl8twCvk0S4Meta?.path ?? "/ru/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    alias: upcomingl8twCvk0S4Meta?.alias || [],
    redirect: upcomingl8twCvk0S4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingl8twCvk0S4Meta?.name ?? "mobile-cybersport-upcoming___es",
    path: upcomingl8twCvk0S4Meta?.path ?? "/es/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    alias: upcomingl8twCvk0S4Meta?.alias || [],
    redirect: upcomingl8twCvk0S4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingl8twCvk0S4Meta?.name ?? "mobile-cybersport-upcoming___pt",
    path: upcomingl8twCvk0S4Meta?.path ?? "/pt/mobile/cybersport/upcoming",
    meta: upcomingl8twCvk0S4Meta || {},
    alias: upcomingl8twCvk0S4Meta?.alias || [],
    redirect: upcomingl8twCvk0S4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/cybersport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: indexQetr5klI9HMeta?.name ?? "mobile___en",
    path: indexQetr5klI9HMeta?.path ?? "/mobile",
    meta: indexQetr5klI9HMeta || {},
    alias: indexQetr5klI9HMeta?.alias || [],
    redirect: indexQetr5klI9HMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexQetr5klI9HMeta?.name ?? "mobile___ru",
    path: indexQetr5klI9HMeta?.path ?? "/ru/mobile",
    meta: indexQetr5klI9HMeta || {},
    alias: indexQetr5klI9HMeta?.alias || [],
    redirect: indexQetr5klI9HMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexQetr5klI9HMeta?.name ?? "mobile___es",
    path: indexQetr5klI9HMeta?.path ?? "/es/mobile",
    meta: indexQetr5klI9HMeta || {},
    alias: indexQetr5klI9HMeta?.alias || [],
    redirect: indexQetr5klI9HMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexQetr5klI9HMeta?.name ?? "mobile___pt",
    path: indexQetr5klI9HMeta?.path ?? "/pt/mobile",
    meta: indexQetr5klI9HMeta || {},
    alias: indexQetr5klI9HMeta?.alias || [],
    redirect: indexQetr5klI9HMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: betsMTi5WP7IbTMeta?.name ?? "mobile-personal-bets___en",
    path: betsMTi5WP7IbTMeta?.path ?? "/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    alias: betsMTi5WP7IbTMeta?.alias || [],
    redirect: betsMTi5WP7IbTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: betsMTi5WP7IbTMeta?.name ?? "mobile-personal-bets___ru",
    path: betsMTi5WP7IbTMeta?.path ?? "/ru/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    alias: betsMTi5WP7IbTMeta?.alias || [],
    redirect: betsMTi5WP7IbTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: betsMTi5WP7IbTMeta?.name ?? "mobile-personal-bets___es",
    path: betsMTi5WP7IbTMeta?.path ?? "/es/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    alias: betsMTi5WP7IbTMeta?.alias || [],
    redirect: betsMTi5WP7IbTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: betsMTi5WP7IbTMeta?.name ?? "mobile-personal-bets___pt",
    path: betsMTi5WP7IbTMeta?.path ?? "/pt/mobile/personal/bets",
    meta: betsMTi5WP7IbTMeta || {},
    alias: betsMTi5WP7IbTMeta?.alias || [],
    redirect: betsMTi5WP7IbTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: kychGo1dZz1ttMeta?.name ?? "mobile-personal-kyc___en",
    path: kychGo1dZz1ttMeta?.path ?? "/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    alias: kychGo1dZz1ttMeta?.alias || [],
    redirect: kychGo1dZz1ttMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: kychGo1dZz1ttMeta?.name ?? "mobile-personal-kyc___ru",
    path: kychGo1dZz1ttMeta?.path ?? "/ru/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    alias: kychGo1dZz1ttMeta?.alias || [],
    redirect: kychGo1dZz1ttMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: kychGo1dZz1ttMeta?.name ?? "mobile-personal-kyc___es",
    path: kychGo1dZz1ttMeta?.path ?? "/es/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    alias: kychGo1dZz1ttMeta?.alias || [],
    redirect: kychGo1dZz1ttMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: kychGo1dZz1ttMeta?.name ?? "mobile-personal-kyc___pt",
    path: kychGo1dZz1ttMeta?.path ?? "/pt/mobile/personal/kyc",
    meta: kychGo1dZz1ttMeta || {},
    alias: kychGo1dZz1ttMeta?.alias || [],
    redirect: kychGo1dZz1ttMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: profile3vJcr8KiKGMeta?.name ?? "mobile-personal-profile___en",
    path: profile3vJcr8KiKGMeta?.path ?? "/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    alias: profile3vJcr8KiKGMeta?.alias || [],
    redirect: profile3vJcr8KiKGMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: profile3vJcr8KiKGMeta?.name ?? "mobile-personal-profile___ru",
    path: profile3vJcr8KiKGMeta?.path ?? "/ru/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    alias: profile3vJcr8KiKGMeta?.alias || [],
    redirect: profile3vJcr8KiKGMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: profile3vJcr8KiKGMeta?.name ?? "mobile-personal-profile___es",
    path: profile3vJcr8KiKGMeta?.path ?? "/es/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    alias: profile3vJcr8KiKGMeta?.alias || [],
    redirect: profile3vJcr8KiKGMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: profile3vJcr8KiKGMeta?.name ?? "mobile-personal-profile___pt",
    path: profile3vJcr8KiKGMeta?.path ?? "/pt/mobile/personal/profile",
    meta: profile3vJcr8KiKGMeta || {},
    alias: profile3vJcr8KiKGMeta?.alias || [],
    redirect: profile3vJcr8KiKGMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsYRoe6PG0BfMeta?.name ?? "mobile-personal-settings___en",
    path: settingsYRoe6PG0BfMeta?.path ?? "/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    alias: settingsYRoe6PG0BfMeta?.alias || [],
    redirect: settingsYRoe6PG0BfMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsYRoe6PG0BfMeta?.name ?? "mobile-personal-settings___ru",
    path: settingsYRoe6PG0BfMeta?.path ?? "/ru/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    alias: settingsYRoe6PG0BfMeta?.alias || [],
    redirect: settingsYRoe6PG0BfMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsYRoe6PG0BfMeta?.name ?? "mobile-personal-settings___es",
    path: settingsYRoe6PG0BfMeta?.path ?? "/es/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    alias: settingsYRoe6PG0BfMeta?.alias || [],
    redirect: settingsYRoe6PG0BfMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsYRoe6PG0BfMeta?.name ?? "mobile-personal-settings___pt",
    path: settingsYRoe6PG0BfMeta?.path ?? "/pt/mobile/personal/settings",
    meta: settingsYRoe6PG0BfMeta || {},
    alias: settingsYRoe6PG0BfMeta?.alias || [],
    redirect: settingsYRoe6PG0BfMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/settings.vue").then(m => m.default || m)
  },
  {
    name: transactionsAMF9N0beyOMeta?.name ?? "mobile-personal-transactions___en",
    path: transactionsAMF9N0beyOMeta?.path ?? "/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    alias: transactionsAMF9N0beyOMeta?.alias || [],
    redirect: transactionsAMF9N0beyOMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsAMF9N0beyOMeta?.name ?? "mobile-personal-transactions___ru",
    path: transactionsAMF9N0beyOMeta?.path ?? "/ru/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    alias: transactionsAMF9N0beyOMeta?.alias || [],
    redirect: transactionsAMF9N0beyOMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsAMF9N0beyOMeta?.name ?? "mobile-personal-transactions___es",
    path: transactionsAMF9N0beyOMeta?.path ?? "/es/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    alias: transactionsAMF9N0beyOMeta?.alias || [],
    redirect: transactionsAMF9N0beyOMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsAMF9N0beyOMeta?.name ?? "mobile-personal-transactions___pt",
    path: transactionsAMF9N0beyOMeta?.path ?? "/pt/mobile/personal/transactions",
    meta: transactionsAMF9N0beyOMeta || {},
    alias: transactionsAMF9N0beyOMeta?.alias || [],
    redirect: transactionsAMF9N0beyOMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: walletXz5gW2EWqtMeta?.name ?? "mobile-personal-wallet___en",
    path: walletXz5gW2EWqtMeta?.path ?? "/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    alias: walletXz5gW2EWqtMeta?.alias || [],
    redirect: walletXz5gW2EWqtMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: walletXz5gW2EWqtMeta?.name ?? "mobile-personal-wallet___ru",
    path: walletXz5gW2EWqtMeta?.path ?? "/ru/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    alias: walletXz5gW2EWqtMeta?.alias || [],
    redirect: walletXz5gW2EWqtMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: walletXz5gW2EWqtMeta?.name ?? "mobile-personal-wallet___es",
    path: walletXz5gW2EWqtMeta?.path ?? "/es/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    alias: walletXz5gW2EWqtMeta?.alias || [],
    redirect: walletXz5gW2EWqtMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: walletXz5gW2EWqtMeta?.name ?? "mobile-personal-wallet___pt",
    path: walletXz5gW2EWqtMeta?.path ?? "/pt/mobile/personal/wallet",
    meta: walletXz5gW2EWqtMeta || {},
    alias: walletXz5gW2EWqtMeta?.alias || [],
    redirect: walletXz5gW2EWqtMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: registrationrbzrfXjjhQMeta?.name ?? "mobile-promo-registration___en",
    path: registrationrbzrfXjjhQMeta?.path ?? "/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    alias: registrationrbzrfXjjhQMeta?.alias || [],
    redirect: registrationrbzrfXjjhQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: registrationrbzrfXjjhQMeta?.name ?? "mobile-promo-registration___ru",
    path: registrationrbzrfXjjhQMeta?.path ?? "/ru/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    alias: registrationrbzrfXjjhQMeta?.alias || [],
    redirect: registrationrbzrfXjjhQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: registrationrbzrfXjjhQMeta?.name ?? "mobile-promo-registration___es",
    path: registrationrbzrfXjjhQMeta?.path ?? "/es/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    alias: registrationrbzrfXjjhQMeta?.alias || [],
    redirect: registrationrbzrfXjjhQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: registrationrbzrfXjjhQMeta?.name ?? "mobile-promo-registration___pt",
    path: registrationrbzrfXjjhQMeta?.path ?? "/pt/mobile/promo/registration",
    meta: registrationrbzrfXjjhQMeta || {},
    alias: registrationrbzrfXjjhQMeta?.alias || [],
    redirect: registrationrbzrfXjjhQMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnVwMEkgnOtnMeta?.name ?? "mobile-refer-and-earn___en",
    path: refer_45and_45earnVwMEkgnOtnMeta?.path ?? "/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    alias: refer_45and_45earnVwMEkgnOtnMeta?.alias || [],
    redirect: refer_45and_45earnVwMEkgnOtnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnVwMEkgnOtnMeta?.name ?? "mobile-refer-and-earn___ru",
    path: refer_45and_45earnVwMEkgnOtnMeta?.path ?? "/ru/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    alias: refer_45and_45earnVwMEkgnOtnMeta?.alias || [],
    redirect: refer_45and_45earnVwMEkgnOtnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnVwMEkgnOtnMeta?.name ?? "mobile-refer-and-earn___es",
    path: refer_45and_45earnVwMEkgnOtnMeta?.path ?? "/es/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    alias: refer_45and_45earnVwMEkgnOtnMeta?.alias || [],
    redirect: refer_45and_45earnVwMEkgnOtnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnVwMEkgnOtnMeta?.name ?? "mobile-refer-and-earn___pt",
    path: refer_45and_45earnVwMEkgnOtnMeta?.path ?? "/pt/mobile/refer-and-earn",
    meta: refer_45and_45earnVwMEkgnOtnMeta || {},
    alias: refer_45and_45earnVwMEkgnOtnMeta?.alias || [],
    redirect: refer_45and_45earnVwMEkgnOtnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: indexbhxh01Kd9wMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId___en",
    path: indexbhxh01Kd9wMeta?.path ?? "/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    alias: indexbhxh01Kd9wMeta?.alias || [],
    redirect: indexbhxh01Kd9wMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbhxh01Kd9wMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId___ru",
    path: indexbhxh01Kd9wMeta?.path ?? "/ru/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    alias: indexbhxh01Kd9wMeta?.alias || [],
    redirect: indexbhxh01Kd9wMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbhxh01Kd9wMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId___es",
    path: indexbhxh01Kd9wMeta?.path ?? "/es/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    alias: indexbhxh01Kd9wMeta?.alias || [],
    redirect: indexbhxh01Kd9wMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbhxh01Kd9wMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId___pt",
    path: indexbhxh01Kd9wMeta?.path ?? "/pt/mobile/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexbhxh01Kd9wMeta || {},
    alias: indexbhxh01Kd9wMeta?.alias || [],
    redirect: indexbhxh01Kd9wMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93hQrAdnwKrmMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId-match-eventId___en",
    path: _91eventId_93hQrAdnwKrmMeta?.path ?? "/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    alias: _91eventId_93hQrAdnwKrmMeta?.alias || [],
    redirect: _91eventId_93hQrAdnwKrmMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93hQrAdnwKrmMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId-match-eventId___ru",
    path: _91eventId_93hQrAdnwKrmMeta?.path ?? "/ru/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    alias: _91eventId_93hQrAdnwKrmMeta?.alias || [],
    redirect: _91eventId_93hQrAdnwKrmMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93hQrAdnwKrmMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId-match-eventId___es",
    path: _91eventId_93hQrAdnwKrmMeta?.path ?? "/es/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    alias: _91eventId_93hQrAdnwKrmMeta?.alias || [],
    redirect: _91eventId_93hQrAdnwKrmMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93hQrAdnwKrmMeta?.name ?? "mobile-sport-sportId-categoryId-tournamentId-match-eventId___pt",
    path: _91eventId_93hQrAdnwKrmMeta?.path ?? "/pt/mobile/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93hQrAdnwKrmMeta || {},
    alias: _91eventId_93hQrAdnwKrmMeta?.alias || [],
    redirect: _91eventId_93hQrAdnwKrmMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: indexTYNAyURMIHMeta?.name ?? "mobile-sport-sportId-categoryId___en",
    path: indexTYNAyURMIHMeta?.path ?? "/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    alias: indexTYNAyURMIHMeta?.alias || [],
    redirect: indexTYNAyURMIHMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTYNAyURMIHMeta?.name ?? "mobile-sport-sportId-categoryId___ru",
    path: indexTYNAyURMIHMeta?.path ?? "/ru/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    alias: indexTYNAyURMIHMeta?.alias || [],
    redirect: indexTYNAyURMIHMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTYNAyURMIHMeta?.name ?? "mobile-sport-sportId-categoryId___es",
    path: indexTYNAyURMIHMeta?.path ?? "/es/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    alias: indexTYNAyURMIHMeta?.alias || [],
    redirect: indexTYNAyURMIHMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTYNAyURMIHMeta?.name ?? "mobile-sport-sportId-categoryId___pt",
    path: indexTYNAyURMIHMeta?.path ?? "/pt/mobile/sport/:sportId()/:categoryId()",
    meta: indexTYNAyURMIHMeta || {},
    alias: indexTYNAyURMIHMeta?.alias || [],
    redirect: indexTYNAyURMIHMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDhmRLHTMMMeta?.name ?? "mobile-sport-sportId___en",
    path: indexpDhmRLHTMMMeta?.path ?? "/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    alias: indexpDhmRLHTMMMeta?.alias || [],
    redirect: indexpDhmRLHTMMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDhmRLHTMMMeta?.name ?? "mobile-sport-sportId___ru",
    path: indexpDhmRLHTMMMeta?.path ?? "/ru/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    alias: indexpDhmRLHTMMMeta?.alias || [],
    redirect: indexpDhmRLHTMMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDhmRLHTMMMeta?.name ?? "mobile-sport-sportId___es",
    path: indexpDhmRLHTMMMeta?.path ?? "/es/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    alias: indexpDhmRLHTMMMeta?.alias || [],
    redirect: indexpDhmRLHTMMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDhmRLHTMMMeta?.name ?? "mobile-sport-sportId___pt",
    path: indexpDhmRLHTMMMeta?.path ?? "/pt/mobile/sport/:sportId()",
    meta: indexpDhmRLHTMMMeta || {},
    alias: indexpDhmRLHTMMMeta?.alias || [],
    redirect: indexpDhmRLHTMMMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: all5lh487dru4Meta?.name ?? "mobile-sport-all___en",
    path: all5lh487dru4Meta?.path ?? "/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    alias: all5lh487dru4Meta?.alias || [],
    redirect: all5lh487dru4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue").then(m => m.default || m)
  },
  {
    name: all5lh487dru4Meta?.name ?? "mobile-sport-all___ru",
    path: all5lh487dru4Meta?.path ?? "/ru/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    alias: all5lh487dru4Meta?.alias || [],
    redirect: all5lh487dru4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue").then(m => m.default || m)
  },
  {
    name: all5lh487dru4Meta?.name ?? "mobile-sport-all___es",
    path: all5lh487dru4Meta?.path ?? "/es/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    alias: all5lh487dru4Meta?.alias || [],
    redirect: all5lh487dru4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue").then(m => m.default || m)
  },
  {
    name: all5lh487dru4Meta?.name ?? "mobile-sport-all___pt",
    path: all5lh487dru4Meta?.path ?? "/pt/mobile/sport/all",
    meta: all5lh487dru4Meta || {},
    alias: all5lh487dru4Meta?.alias || [],
    redirect: all5lh487dru4Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/all.vue").then(m => m.default || m)
  },
  {
    name: indexqEDppdwYXwMeta?.name ?? "mobile-sport___en",
    path: indexqEDppdwYXwMeta?.path ?? "/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    alias: indexqEDppdwYXwMeta?.alias || [],
    redirect: indexqEDppdwYXwMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue").then(m => m.default || m)
  },
  {
    name: indexqEDppdwYXwMeta?.name ?? "mobile-sport___ru",
    path: indexqEDppdwYXwMeta?.path ?? "/ru/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    alias: indexqEDppdwYXwMeta?.alias || [],
    redirect: indexqEDppdwYXwMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue").then(m => m.default || m)
  },
  {
    name: indexqEDppdwYXwMeta?.name ?? "mobile-sport___es",
    path: indexqEDppdwYXwMeta?.path ?? "/es/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    alias: indexqEDppdwYXwMeta?.alias || [],
    redirect: indexqEDppdwYXwMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue").then(m => m.default || m)
  },
  {
    name: indexqEDppdwYXwMeta?.name ?? "mobile-sport___pt",
    path: indexqEDppdwYXwMeta?.path ?? "/pt/mobile/sport",
    meta: indexqEDppdwYXwMeta || {},
    alias: indexqEDppdwYXwMeta?.alias || [],
    redirect: indexqEDppdwYXwMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/index.vue").then(m => m.default || m)
  },
  {
    name: liveGDPetqsNd2Meta?.name ?? "mobile-sport-live___en",
    path: liveGDPetqsNd2Meta?.path ?? "/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    alias: liveGDPetqsNd2Meta?.alias || [],
    redirect: liveGDPetqsNd2Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue").then(m => m.default || m)
  },
  {
    name: liveGDPetqsNd2Meta?.name ?? "mobile-sport-live___ru",
    path: liveGDPetqsNd2Meta?.path ?? "/ru/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    alias: liveGDPetqsNd2Meta?.alias || [],
    redirect: liveGDPetqsNd2Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue").then(m => m.default || m)
  },
  {
    name: liveGDPetqsNd2Meta?.name ?? "mobile-sport-live___es",
    path: liveGDPetqsNd2Meta?.path ?? "/es/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    alias: liveGDPetqsNd2Meta?.alias || [],
    redirect: liveGDPetqsNd2Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue").then(m => m.default || m)
  },
  {
    name: liveGDPetqsNd2Meta?.name ?? "mobile-sport-live___pt",
    path: liveGDPetqsNd2Meta?.path ?? "/pt/mobile/sport/live",
    meta: liveGDPetqsNd2Meta || {},
    alias: liveGDPetqsNd2Meta?.alias || [],
    redirect: liveGDPetqsNd2Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/live.vue").then(m => m.default || m)
  },
  {
    name: upcomingjgGita4WfNMeta?.name ?? "mobile-sport-upcoming___en",
    path: upcomingjgGita4WfNMeta?.path ?? "/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    alias: upcomingjgGita4WfNMeta?.alias || [],
    redirect: upcomingjgGita4WfNMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingjgGita4WfNMeta?.name ?? "mobile-sport-upcoming___ru",
    path: upcomingjgGita4WfNMeta?.path ?? "/ru/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    alias: upcomingjgGita4WfNMeta?.alias || [],
    redirect: upcomingjgGita4WfNMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingjgGita4WfNMeta?.name ?? "mobile-sport-upcoming___es",
    path: upcomingjgGita4WfNMeta?.path ?? "/es/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    alias: upcomingjgGita4WfNMeta?.alias || [],
    redirect: upcomingjgGita4WfNMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcomingjgGita4WfNMeta?.name ?? "mobile-sport-upcoming___pt",
    path: upcomingjgGita4WfNMeta?.path ?? "/pt/mobile/sport/upcoming",
    meta: upcomingjgGita4WfNMeta || {},
    alias: upcomingjgGita4WfNMeta?.alias || [],
    redirect: upcomingjgGita4WfNMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: supportamedNFbChdMeta?.name ?? "mobile-support___en",
    path: supportamedNFbChdMeta?.path ?? "/mobile/support",
    meta: supportamedNFbChdMeta || {},
    alias: supportamedNFbChdMeta?.alias || [],
    redirect: supportamedNFbChdMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/support.vue").then(m => m.default || m)
  },
  {
    name: supportamedNFbChdMeta?.name ?? "mobile-support___ru",
    path: supportamedNFbChdMeta?.path ?? "/ru/mobile/support",
    meta: supportamedNFbChdMeta || {},
    alias: supportamedNFbChdMeta?.alias || [],
    redirect: supportamedNFbChdMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/support.vue").then(m => m.default || m)
  },
  {
    name: supportamedNFbChdMeta?.name ?? "mobile-support___es",
    path: supportamedNFbChdMeta?.path ?? "/es/mobile/support",
    meta: supportamedNFbChdMeta || {},
    alias: supportamedNFbChdMeta?.alias || [],
    redirect: supportamedNFbChdMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/support.vue").then(m => m.default || m)
  },
  {
    name: supportamedNFbChdMeta?.name ?? "mobile-support___pt",
    path: supportamedNFbChdMeta?.path ?? "/pt/mobile/support",
    meta: supportamedNFbChdMeta || {},
    alias: supportamedNFbChdMeta?.alias || [],
    redirect: supportamedNFbChdMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/support.vue").then(m => m.default || m)
  },
  {
    name: _91id_93uSojnDCrRoMeta?.name ?? "mobile-tournaments-id___en",
    path: _91id_93uSojnDCrRoMeta?.path ?? "/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    alias: _91id_93uSojnDCrRoMeta?.alias || [],
    redirect: _91id_93uSojnDCrRoMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uSojnDCrRoMeta?.name ?? "mobile-tournaments-id___ru",
    path: _91id_93uSojnDCrRoMeta?.path ?? "/ru/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    alias: _91id_93uSojnDCrRoMeta?.alias || [],
    redirect: _91id_93uSojnDCrRoMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uSojnDCrRoMeta?.name ?? "mobile-tournaments-id___es",
    path: _91id_93uSojnDCrRoMeta?.path ?? "/es/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    alias: _91id_93uSojnDCrRoMeta?.alias || [],
    redirect: _91id_93uSojnDCrRoMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uSojnDCrRoMeta?.name ?? "mobile-tournaments-id___pt",
    path: _91id_93uSojnDCrRoMeta?.path ?? "/pt/mobile/tournaments/:id()",
    meta: _91id_93uSojnDCrRoMeta || {},
    alias: _91id_93uSojnDCrRoMeta?.alias || [],
    redirect: _91id_93uSojnDCrRoMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: index2Rf6gY2o1DMeta?.name ?? "mobile-tournaments___en",
    path: index2Rf6gY2o1DMeta?.path ?? "/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    alias: index2Rf6gY2o1DMeta?.alias || [],
    redirect: index2Rf6gY2o1DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: index2Rf6gY2o1DMeta?.name ?? "mobile-tournaments___ru",
    path: index2Rf6gY2o1DMeta?.path ?? "/ru/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    alias: index2Rf6gY2o1DMeta?.alias || [],
    redirect: index2Rf6gY2o1DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: index2Rf6gY2o1DMeta?.name ?? "mobile-tournaments___es",
    path: index2Rf6gY2o1DMeta?.path ?? "/es/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    alias: index2Rf6gY2o1DMeta?.alias || [],
    redirect: index2Rf6gY2o1DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: index2Rf6gY2o1DMeta?.name ?? "mobile-tournaments___pt",
    path: index2Rf6gY2o1DMeta?.path ?? "/pt/mobile/tournaments",
    meta: index2Rf6gY2o1DMeta || {},
    alias: index2Rf6gY2o1DMeta?.alias || [],
    redirect: index2Rf6gY2o1DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: unsubscribesnfj6WpwZ6Meta?.name ?? "mobile-unsubscribe___en",
    path: unsubscribesnfj6WpwZ6Meta?.path ?? "/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    alias: unsubscribesnfj6WpwZ6Meta?.alias || [],
    redirect: unsubscribesnfj6WpwZ6Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribesnfj6WpwZ6Meta?.name ?? "mobile-unsubscribe___ru",
    path: unsubscribesnfj6WpwZ6Meta?.path ?? "/ru/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    alias: unsubscribesnfj6WpwZ6Meta?.alias || [],
    redirect: unsubscribesnfj6WpwZ6Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribesnfj6WpwZ6Meta?.name ?? "mobile-unsubscribe___es",
    path: unsubscribesnfj6WpwZ6Meta?.path ?? "/es/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    alias: unsubscribesnfj6WpwZ6Meta?.alias || [],
    redirect: unsubscribesnfj6WpwZ6Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribesnfj6WpwZ6Meta?.name ?? "mobile-unsubscribe___pt",
    path: unsubscribesnfj6WpwZ6Meta?.path ?? "/pt/mobile/unsubscribe",
    meta: unsubscribesnfj6WpwZ6Meta || {},
    alias: unsubscribesnfj6WpwZ6Meta?.alias || [],
    redirect: unsubscribesnfj6WpwZ6Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/mobile/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: bets55PXI7Bq75Meta?.name ?? "personal-bets___en",
    path: bets55PXI7Bq75Meta?.path ?? "/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    alias: bets55PXI7Bq75Meta?.alias || [],
    redirect: bets55PXI7Bq75Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: bets55PXI7Bq75Meta?.name ?? "personal-bets___ru",
    path: bets55PXI7Bq75Meta?.path ?? "/ru/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    alias: bets55PXI7Bq75Meta?.alias || [],
    redirect: bets55PXI7Bq75Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: bets55PXI7Bq75Meta?.name ?? "personal-bets___es",
    path: bets55PXI7Bq75Meta?.path ?? "/es/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    alias: bets55PXI7Bq75Meta?.alias || [],
    redirect: bets55PXI7Bq75Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: bets55PXI7Bq75Meta?.name ?? "personal-bets___pt",
    path: bets55PXI7Bq75Meta?.path ?? "/pt/personal/bets",
    meta: bets55PXI7Bq75Meta || {},
    alias: bets55PXI7Bq75Meta?.alias || [],
    redirect: bets55PXI7Bq75Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/bets.vue").then(m => m.default || m)
  },
  {
    name: kyc2QSbsoWkUkMeta?.name ?? "personal-kyc___en",
    path: kyc2QSbsoWkUkMeta?.path ?? "/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    alias: kyc2QSbsoWkUkMeta?.alias || [],
    redirect: kyc2QSbsoWkUkMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: kyc2QSbsoWkUkMeta?.name ?? "personal-kyc___ru",
    path: kyc2QSbsoWkUkMeta?.path ?? "/ru/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    alias: kyc2QSbsoWkUkMeta?.alias || [],
    redirect: kyc2QSbsoWkUkMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: kyc2QSbsoWkUkMeta?.name ?? "personal-kyc___es",
    path: kyc2QSbsoWkUkMeta?.path ?? "/es/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    alias: kyc2QSbsoWkUkMeta?.alias || [],
    redirect: kyc2QSbsoWkUkMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: kyc2QSbsoWkUkMeta?.name ?? "personal-kyc___pt",
    path: kyc2QSbsoWkUkMeta?.path ?? "/pt/personal/kyc",
    meta: kyc2QSbsoWkUkMeta || {},
    alias: kyc2QSbsoWkUkMeta?.alias || [],
    redirect: kyc2QSbsoWkUkMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/kyc.vue").then(m => m.default || m)
  },
  {
    name: profileGMGu9bdYmBMeta?.name ?? "personal-profile___en",
    path: profileGMGu9bdYmBMeta?.path ?? "/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    alias: profileGMGu9bdYmBMeta?.alias || [],
    redirect: profileGMGu9bdYmBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: profileGMGu9bdYmBMeta?.name ?? "personal-profile___ru",
    path: profileGMGu9bdYmBMeta?.path ?? "/ru/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    alias: profileGMGu9bdYmBMeta?.alias || [],
    redirect: profileGMGu9bdYmBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: profileGMGu9bdYmBMeta?.name ?? "personal-profile___es",
    path: profileGMGu9bdYmBMeta?.path ?? "/es/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    alias: profileGMGu9bdYmBMeta?.alias || [],
    redirect: profileGMGu9bdYmBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: profileGMGu9bdYmBMeta?.name ?? "personal-profile___pt",
    path: profileGMGu9bdYmBMeta?.path ?? "/pt/personal/profile",
    meta: profileGMGu9bdYmBMeta || {},
    alias: profileGMGu9bdYmBMeta?.alias || [],
    redirect: profileGMGu9bdYmBMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/profile.vue").then(m => m.default || m)
  },
  {
    name: transactionsxL2rxTO656Meta?.name ?? "personal-transactions___en",
    path: transactionsxL2rxTO656Meta?.path ?? "/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    alias: transactionsxL2rxTO656Meta?.alias || [],
    redirect: transactionsxL2rxTO656Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsxL2rxTO656Meta?.name ?? "personal-transactions___ru",
    path: transactionsxL2rxTO656Meta?.path ?? "/ru/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    alias: transactionsxL2rxTO656Meta?.alias || [],
    redirect: transactionsxL2rxTO656Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsxL2rxTO656Meta?.name ?? "personal-transactions___es",
    path: transactionsxL2rxTO656Meta?.path ?? "/es/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    alias: transactionsxL2rxTO656Meta?.alias || [],
    redirect: transactionsxL2rxTO656Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: transactionsxL2rxTO656Meta?.name ?? "personal-transactions___pt",
    path: transactionsxL2rxTO656Meta?.path ?? "/pt/personal/transactions",
    meta: transactionsxL2rxTO656Meta || {},
    alias: transactionsxL2rxTO656Meta?.alias || [],
    redirect: transactionsxL2rxTO656Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/transactions.vue").then(m => m.default || m)
  },
  {
    name: walletZ0QEOx9alnMeta?.name ?? "personal-wallet___en",
    path: walletZ0QEOx9alnMeta?.path ?? "/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    alias: walletZ0QEOx9alnMeta?.alias || [],
    redirect: walletZ0QEOx9alnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: walletZ0QEOx9alnMeta?.name ?? "personal-wallet___ru",
    path: walletZ0QEOx9alnMeta?.path ?? "/ru/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    alias: walletZ0QEOx9alnMeta?.alias || [],
    redirect: walletZ0QEOx9alnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: walletZ0QEOx9alnMeta?.name ?? "personal-wallet___es",
    path: walletZ0QEOx9alnMeta?.path ?? "/es/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    alias: walletZ0QEOx9alnMeta?.alias || [],
    redirect: walletZ0QEOx9alnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: walletZ0QEOx9alnMeta?.name ?? "personal-wallet___pt",
    path: walletZ0QEOx9alnMeta?.path ?? "/pt/personal/wallet",
    meta: walletZ0QEOx9alnMeta || {},
    alias: walletZ0QEOx9alnMeta?.alias || [],
    redirect: walletZ0QEOx9alnMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/personal/wallet.vue").then(m => m.default || m)
  },
  {
    name: registrationiZrL1sJ8iWMeta?.name ?? "promo-registration___en",
    path: registrationiZrL1sJ8iWMeta?.path ?? "/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    alias: registrationiZrL1sJ8iWMeta?.alias || [],
    redirect: registrationiZrL1sJ8iWMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: registrationiZrL1sJ8iWMeta?.name ?? "promo-registration___ru",
    path: registrationiZrL1sJ8iWMeta?.path ?? "/ru/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    alias: registrationiZrL1sJ8iWMeta?.alias || [],
    redirect: registrationiZrL1sJ8iWMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: registrationiZrL1sJ8iWMeta?.name ?? "promo-registration___es",
    path: registrationiZrL1sJ8iWMeta?.path ?? "/es/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    alias: registrationiZrL1sJ8iWMeta?.alias || [],
    redirect: registrationiZrL1sJ8iWMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: registrationiZrL1sJ8iWMeta?.name ?? "promo-registration___pt",
    path: registrationiZrL1sJ8iWMeta?.path ?? "/pt/promo/registration",
    meta: registrationiZrL1sJ8iWMeta || {},
    alias: registrationiZrL1sJ8iWMeta?.alias || [],
    redirect: registrationiZrL1sJ8iWMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/promo/registration.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnSrBsLbldv3Meta?.name ?? "refer-and-earn___en",
    path: refer_45and_45earnSrBsLbldv3Meta?.path ?? "/refer-and-earn",
    meta: refer_45and_45earnSrBsLbldv3Meta || {},
    alias: refer_45and_45earnSrBsLbldv3Meta?.alias || [],
    redirect: refer_45and_45earnSrBsLbldv3Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnSrBsLbldv3Meta?.name ?? "refer-and-earn___ru",
    path: refer_45and_45earnSrBsLbldv3Meta?.path ?? "/ru/refer-and-earn",
    meta: refer_45and_45earnSrBsLbldv3Meta || {},
    alias: refer_45and_45earnSrBsLbldv3Meta?.alias || [],
    redirect: refer_45and_45earnSrBsLbldv3Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnSrBsLbldv3Meta?.name ?? "refer-and-earn___es",
    path: refer_45and_45earnSrBsLbldv3Meta?.path ?? "/es/refer-and-earn",
    meta: refer_45and_45earnSrBsLbldv3Meta || {},
    alias: refer_45and_45earnSrBsLbldv3Meta?.alias || [],
    redirect: refer_45and_45earnSrBsLbldv3Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: refer_45and_45earnSrBsLbldv3Meta?.name ?? "refer-and-earn___pt",
    path: refer_45and_45earnSrBsLbldv3Meta?.path ?? "/pt/refer-and-earn",
    meta: refer_45and_45earnSrBsLbldv3Meta || {},
    alias: refer_45and_45earnSrBsLbldv3Meta?.alias || [],
    redirect: refer_45and_45earnSrBsLbldv3Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/refer-and-earn.vue").then(m => m.default || m)
  },
  {
    name: indexKKlwbRssCTMeta?.name ?? "sport-sportId-categoryId-tournamentId___en",
    path: indexKKlwbRssCTMeta?.path ?? "/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexKKlwbRssCTMeta || {},
    alias: indexKKlwbRssCTMeta?.alias || [],
    redirect: indexKKlwbRssCTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKKlwbRssCTMeta?.name ?? "sport-sportId-categoryId-tournamentId___ru",
    path: indexKKlwbRssCTMeta?.path ?? "/ru/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexKKlwbRssCTMeta || {},
    alias: indexKKlwbRssCTMeta?.alias || [],
    redirect: indexKKlwbRssCTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKKlwbRssCTMeta?.name ?? "sport-sportId-categoryId-tournamentId___es",
    path: indexKKlwbRssCTMeta?.path ?? "/es/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexKKlwbRssCTMeta || {},
    alias: indexKKlwbRssCTMeta?.alias || [],
    redirect: indexKKlwbRssCTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKKlwbRssCTMeta?.name ?? "sport-sportId-categoryId-tournamentId___pt",
    path: indexKKlwbRssCTMeta?.path ?? "/pt/sport/:sportId()/:categoryId()/:tournamentId()",
    meta: indexKKlwbRssCTMeta || {},
    alias: indexKKlwbRssCTMeta?.alias || [],
    redirect: indexKKlwbRssCTMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93UYUQdP4EI8Meta?.name ?? "sport-sportId-categoryId-tournamentId-match-eventId___en",
    path: _91eventId_93UYUQdP4EI8Meta?.path ?? "/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    alias: _91eventId_93UYUQdP4EI8Meta?.alias || [],
    redirect: _91eventId_93UYUQdP4EI8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93UYUQdP4EI8Meta?.name ?? "sport-sportId-categoryId-tournamentId-match-eventId___ru",
    path: _91eventId_93UYUQdP4EI8Meta?.path ?? "/ru/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    alias: _91eventId_93UYUQdP4EI8Meta?.alias || [],
    redirect: _91eventId_93UYUQdP4EI8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93UYUQdP4EI8Meta?.name ?? "sport-sportId-categoryId-tournamentId-match-eventId___es",
    path: _91eventId_93UYUQdP4EI8Meta?.path ?? "/es/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    alias: _91eventId_93UYUQdP4EI8Meta?.alias || [],
    redirect: _91eventId_93UYUQdP4EI8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93UYUQdP4EI8Meta?.name ?? "sport-sportId-categoryId-tournamentId-match-eventId___pt",
    path: _91eventId_93UYUQdP4EI8Meta?.path ?? "/pt/sport/:sportId()/:categoryId()/:tournamentId()/match/:eventId()",
    meta: _91eventId_93UYUQdP4EI8Meta || {},
    alias: _91eventId_93UYUQdP4EI8Meta?.alias || [],
    redirect: _91eventId_93UYUQdP4EI8Meta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/[tournamentId]/match/[eventId].vue").then(m => m.default || m)
  },
  {
    name: indexZA8AreH4nrMeta?.name ?? "sport-sportId-categoryId___en",
    path: indexZA8AreH4nrMeta?.path ?? "/sport/:sportId()/:categoryId()",
    meta: indexZA8AreH4nrMeta || {},
    alias: indexZA8AreH4nrMeta?.alias || [],
    redirect: indexZA8AreH4nrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZA8AreH4nrMeta?.name ?? "sport-sportId-categoryId___ru",
    path: indexZA8AreH4nrMeta?.path ?? "/ru/sport/:sportId()/:categoryId()",
    meta: indexZA8AreH4nrMeta || {},
    alias: indexZA8AreH4nrMeta?.alias || [],
    redirect: indexZA8AreH4nrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZA8AreH4nrMeta?.name ?? "sport-sportId-categoryId___es",
    path: indexZA8AreH4nrMeta?.path ?? "/es/sport/:sportId()/:categoryId()",
    meta: indexZA8AreH4nrMeta || {},
    alias: indexZA8AreH4nrMeta?.alias || [],
    redirect: indexZA8AreH4nrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZA8AreH4nrMeta?.name ?? "sport-sportId-categoryId___pt",
    path: indexZA8AreH4nrMeta?.path ?? "/pt/sport/:sportId()/:categoryId()",
    meta: indexZA8AreH4nrMeta || {},
    alias: indexZA8AreH4nrMeta?.alias || [],
    redirect: indexZA8AreH4nrMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXqtKpNJIITMeta?.name ?? "sport-sportId___en",
    path: indexXqtKpNJIITMeta?.path ?? "/sport/:sportId()",
    meta: indexXqtKpNJIITMeta || {},
    alias: indexXqtKpNJIITMeta?.alias || [],
    redirect: indexXqtKpNJIITMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXqtKpNJIITMeta?.name ?? "sport-sportId___ru",
    path: indexXqtKpNJIITMeta?.path ?? "/ru/sport/:sportId()",
    meta: indexXqtKpNJIITMeta || {},
    alias: indexXqtKpNJIITMeta?.alias || [],
    redirect: indexXqtKpNJIITMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXqtKpNJIITMeta?.name ?? "sport-sportId___es",
    path: indexXqtKpNJIITMeta?.path ?? "/es/sport/:sportId()",
    meta: indexXqtKpNJIITMeta || {},
    alias: indexXqtKpNJIITMeta?.alias || [],
    redirect: indexXqtKpNJIITMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXqtKpNJIITMeta?.name ?? "sport-sportId___pt",
    path: indexXqtKpNJIITMeta?.path ?? "/pt/sport/:sportId()",
    meta: indexXqtKpNJIITMeta || {},
    alias: indexXqtKpNJIITMeta?.alias || [],
    redirect: indexXqtKpNJIITMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/[sportId]/index.vue").then(m => m.default || m)
  },
  {
    name: allvuzAUqv2gaMeta?.name ?? "sport-all___en",
    path: allvuzAUqv2gaMeta?.path ?? "/sport/all",
    meta: allvuzAUqv2gaMeta || {},
    alias: allvuzAUqv2gaMeta?.alias || [],
    redirect: allvuzAUqv2gaMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/all.vue").then(m => m.default || m)
  },
  {
    name: allvuzAUqv2gaMeta?.name ?? "sport-all___ru",
    path: allvuzAUqv2gaMeta?.path ?? "/ru/sport/all",
    meta: allvuzAUqv2gaMeta || {},
    alias: allvuzAUqv2gaMeta?.alias || [],
    redirect: allvuzAUqv2gaMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/all.vue").then(m => m.default || m)
  },
  {
    name: allvuzAUqv2gaMeta?.name ?? "sport-all___es",
    path: allvuzAUqv2gaMeta?.path ?? "/es/sport/all",
    meta: allvuzAUqv2gaMeta || {},
    alias: allvuzAUqv2gaMeta?.alias || [],
    redirect: allvuzAUqv2gaMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/all.vue").then(m => m.default || m)
  },
  {
    name: allvuzAUqv2gaMeta?.name ?? "sport-all___pt",
    path: allvuzAUqv2gaMeta?.path ?? "/pt/sport/all",
    meta: allvuzAUqv2gaMeta || {},
    alias: allvuzAUqv2gaMeta?.alias || [],
    redirect: allvuzAUqv2gaMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/all.vue").then(m => m.default || m)
  },
  {
    name: indexYaADUjozNSMeta?.name ?? "sport___en",
    path: indexYaADUjozNSMeta?.path ?? "/sport",
    meta: indexYaADUjozNSMeta || {},
    alias: indexYaADUjozNSMeta?.alias || [],
    redirect: indexYaADUjozNSMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaADUjozNSMeta?.name ?? "sport___ru",
    path: indexYaADUjozNSMeta?.path ?? "/ru/sport",
    meta: indexYaADUjozNSMeta || {},
    alias: indexYaADUjozNSMeta?.alias || [],
    redirect: indexYaADUjozNSMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaADUjozNSMeta?.name ?? "sport___es",
    path: indexYaADUjozNSMeta?.path ?? "/es/sport",
    meta: indexYaADUjozNSMeta || {},
    alias: indexYaADUjozNSMeta?.alias || [],
    redirect: indexYaADUjozNSMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaADUjozNSMeta?.name ?? "sport___pt",
    path: indexYaADUjozNSMeta?.path ?? "/pt/sport",
    meta: indexYaADUjozNSMeta || {},
    alias: indexYaADUjozNSMeta?.alias || [],
    redirect: indexYaADUjozNSMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/index.vue").then(m => m.default || m)
  },
  {
    name: liverNxADqpTpFMeta?.name ?? "sport-live___en",
    path: liverNxADqpTpFMeta?.path ?? "/sport/live",
    meta: liverNxADqpTpFMeta || {},
    alias: liverNxADqpTpFMeta?.alias || [],
    redirect: liverNxADqpTpFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/live.vue").then(m => m.default || m)
  },
  {
    name: liverNxADqpTpFMeta?.name ?? "sport-live___ru",
    path: liverNxADqpTpFMeta?.path ?? "/ru/sport/live",
    meta: liverNxADqpTpFMeta || {},
    alias: liverNxADqpTpFMeta?.alias || [],
    redirect: liverNxADqpTpFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/live.vue").then(m => m.default || m)
  },
  {
    name: liverNxADqpTpFMeta?.name ?? "sport-live___es",
    path: liverNxADqpTpFMeta?.path ?? "/es/sport/live",
    meta: liverNxADqpTpFMeta || {},
    alias: liverNxADqpTpFMeta?.alias || [],
    redirect: liverNxADqpTpFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/live.vue").then(m => m.default || m)
  },
  {
    name: liverNxADqpTpFMeta?.name ?? "sport-live___pt",
    path: liverNxADqpTpFMeta?.path ?? "/pt/sport/live",
    meta: liverNxADqpTpFMeta || {},
    alias: liverNxADqpTpFMeta?.alias || [],
    redirect: liverNxADqpTpFMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/live.vue").then(m => m.default || m)
  },
  {
    name: upcoming55fiukpALUMeta?.name ?? "sport-upcoming___en",
    path: upcoming55fiukpALUMeta?.path ?? "/sport/upcoming",
    meta: upcoming55fiukpALUMeta || {},
    alias: upcoming55fiukpALUMeta?.alias || [],
    redirect: upcoming55fiukpALUMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcoming55fiukpALUMeta?.name ?? "sport-upcoming___ru",
    path: upcoming55fiukpALUMeta?.path ?? "/ru/sport/upcoming",
    meta: upcoming55fiukpALUMeta || {},
    alias: upcoming55fiukpALUMeta?.alias || [],
    redirect: upcoming55fiukpALUMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcoming55fiukpALUMeta?.name ?? "sport-upcoming___es",
    path: upcoming55fiukpALUMeta?.path ?? "/es/sport/upcoming",
    meta: upcoming55fiukpALUMeta || {},
    alias: upcoming55fiukpALUMeta?.alias || [],
    redirect: upcoming55fiukpALUMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: upcoming55fiukpALUMeta?.name ?? "sport-upcoming___pt",
    path: upcoming55fiukpALUMeta?.path ?? "/pt/sport/upcoming",
    meta: upcoming55fiukpALUMeta || {},
    alias: upcoming55fiukpALUMeta?.alias || [],
    redirect: upcoming55fiukpALUMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/sport/upcoming.vue").then(m => m.default || m)
  },
  {
    name: supportRafuRYvRSeMeta?.name ?? "support___en",
    path: supportRafuRYvRSeMeta?.path ?? "/support",
    meta: supportRafuRYvRSeMeta || {},
    alias: supportRafuRYvRSeMeta?.alias || [],
    redirect: supportRafuRYvRSeMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportRafuRYvRSeMeta?.name ?? "support___ru",
    path: supportRafuRYvRSeMeta?.path ?? "/ru/support",
    meta: supportRafuRYvRSeMeta || {},
    alias: supportRafuRYvRSeMeta?.alias || [],
    redirect: supportRafuRYvRSeMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportRafuRYvRSeMeta?.name ?? "support___es",
    path: supportRafuRYvRSeMeta?.path ?? "/es/support",
    meta: supportRafuRYvRSeMeta || {},
    alias: supportRafuRYvRSeMeta?.alias || [],
    redirect: supportRafuRYvRSeMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/support.vue").then(m => m.default || m)
  },
  {
    name: supportRafuRYvRSeMeta?.name ?? "support___pt",
    path: supportRafuRYvRSeMeta?.path ?? "/pt/support",
    meta: supportRafuRYvRSeMeta || {},
    alias: supportRafuRYvRSeMeta?.alias || [],
    redirect: supportRafuRYvRSeMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/support.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tWYqDm8JarMeta?.name ?? "tournaments-id___en",
    path: _91id_93tWYqDm8JarMeta?.path ?? "/tournaments/:id()",
    meta: _91id_93tWYqDm8JarMeta || {},
    alias: _91id_93tWYqDm8JarMeta?.alias || [],
    redirect: _91id_93tWYqDm8JarMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tWYqDm8JarMeta?.name ?? "tournaments-id___ru",
    path: _91id_93tWYqDm8JarMeta?.path ?? "/ru/tournaments/:id()",
    meta: _91id_93tWYqDm8JarMeta || {},
    alias: _91id_93tWYqDm8JarMeta?.alias || [],
    redirect: _91id_93tWYqDm8JarMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tWYqDm8JarMeta?.name ?? "tournaments-id___es",
    path: _91id_93tWYqDm8JarMeta?.path ?? "/es/tournaments/:id()",
    meta: _91id_93tWYqDm8JarMeta || {},
    alias: _91id_93tWYqDm8JarMeta?.alias || [],
    redirect: _91id_93tWYqDm8JarMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tWYqDm8JarMeta?.name ?? "tournaments-id___pt",
    path: _91id_93tWYqDm8JarMeta?.path ?? "/pt/tournaments/:id()",
    meta: _91id_93tWYqDm8JarMeta || {},
    alias: _91id_93tWYqDm8JarMeta?.alias || [],
    redirect: _91id_93tWYqDm8JarMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVwhhIVl36QMeta?.name ?? "tournaments___en",
    path: indexVwhhIVl36QMeta?.path ?? "/tournaments",
    meta: indexVwhhIVl36QMeta || {},
    alias: indexVwhhIVl36QMeta?.alias || [],
    redirect: indexVwhhIVl36QMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwhhIVl36QMeta?.name ?? "tournaments___ru",
    path: indexVwhhIVl36QMeta?.path ?? "/ru/tournaments",
    meta: indexVwhhIVl36QMeta || {},
    alias: indexVwhhIVl36QMeta?.alias || [],
    redirect: indexVwhhIVl36QMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwhhIVl36QMeta?.name ?? "tournaments___es",
    path: indexVwhhIVl36QMeta?.path ?? "/es/tournaments",
    meta: indexVwhhIVl36QMeta || {},
    alias: indexVwhhIVl36QMeta?.alias || [],
    redirect: indexVwhhIVl36QMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwhhIVl36QMeta?.name ?? "tournaments___pt",
    path: indexVwhhIVl36QMeta?.path ?? "/pt/tournaments",
    meta: indexVwhhIVl36QMeta || {},
    alias: indexVwhhIVl36QMeta?.alias || [],
    redirect: indexVwhhIVl36QMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe67u1rkBt9DMeta?.name ?? "unsubscribe___en",
    path: unsubscribe67u1rkBt9DMeta?.path ?? "/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    alias: unsubscribe67u1rkBt9DMeta?.alias || [],
    redirect: unsubscribe67u1rkBt9DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe67u1rkBt9DMeta?.name ?? "unsubscribe___ru",
    path: unsubscribe67u1rkBt9DMeta?.path ?? "/ru/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    alias: unsubscribe67u1rkBt9DMeta?.alias || [],
    redirect: unsubscribe67u1rkBt9DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe67u1rkBt9DMeta?.name ?? "unsubscribe___es",
    path: unsubscribe67u1rkBt9DMeta?.path ?? "/es/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    alias: unsubscribe67u1rkBt9DMeta?.alias || [],
    redirect: unsubscribe67u1rkBt9DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribe67u1rkBt9DMeta?.name ?? "unsubscribe___pt",
    path: unsubscribe67u1rkBt9DMeta?.path ?? "/pt/unsubscribe",
    meta: unsubscribe67u1rkBt9DMeta || {},
    alias: unsubscribe67u1rkBt9DMeta?.alias || [],
    redirect: unsubscribe67u1rkBt9DMeta?.redirect || undefined,
    component: () => import("/app/apps/site/pages/unsubscribe.vue").then(m => m.default || m)
  }
]