import { useUserStore } from '@st/user/stores/useUserStore'
import { useAccountsStore } from '../stores/useAccountsStore'
import { useCurrencies } from '../stores/currencies'
import { useTariffsStore } from '../stores/useTariffsStore'
import { useAddressesStore } from '../stores/useAddressesStore'
import { useConverterStore } from '../stores/useConverterStore'

export default defineNuxtPlugin({
  name: 'paymentStoresInit',
  order: 3,
  async setup() {
    const { isAuthenticated } = storeToRefs(useUserStore())

    const { $init } = useNuxtApp()
    const { getCurrenciesAndNetworks, setCurrencies } = useCurrencies()
    const { fetchCurrencyRates } = useConverterStore()
    const { getAccountList, resetAccounts } = useAccountsStore()
    const { getAddresses } = useAddressesStore()
    const { getTariffs } = useTariffsStore()

    setCurrencies($init?.currencies ?? [])

    watchEffect(async () => {
      if (isAuthenticated.value) {
        await getCurrenciesAndNetworks()
        await fetchCurrencyRates()
        await getAccountList()
        getAddresses()
        getTariffs()
      } else {
        resetAccounts()
      }
    })
  },
})
