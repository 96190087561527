import Decimal from '@st/decimal'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { DepositBonus } from '../types'

export const useDepositBonusesStore = defineStore('depositBonuses', () => {
  const stFetch = useRawStFetch()

  const bonuses = ref<DepositBonus[]>([])
  const bonusesCount = computed(() => bonuses.value.length)
  const newBonuses = computed(() => bonuses.value.filter((item) => !item.bonus))
  const favoriteNewDepositBonus = computed(() =>
    newBonuses.value.reduce<DepositBonus | null>(
      (prev, current) =>
        new Decimal(prev?.bonusSettings?.depositMultiplier ?? 0) >
        new Decimal(current.bonusSettings.depositMultiplier)
          ? prev
          : current,
      null,
    ),
  )

  async function fetchDepositBonuses() {
    try {
      const response = await stFetch('/bonus-for-deposit/find', {
        method: 'post',
        body: {
          params: {},
          pagination: {
            page: 0,
            perPage: 50,
            orderBy: [
              {
                fieldName: 'createdAt',
                sortOrder: 'DESC',
              },
            ],
          },
        },
      })
      bonuses.value = response.data
    } catch (error) {
      console.error('Failed to load deposit bonuses', error)
    }
  }

  const router = useRouter()

  async function handleDepositModalShow() {
    if (!bonuses.value.length) {
      await fetchDepositBonuses()
    }

    if (favoriteNewDepositBonus.value) {
      router.replace({ query: { modal: 'depositBonus' } })
    }
  }

  const { isAuthenticated } = storeToRefs(useUserStore())
  watchEffect(() => {
    if (isAuthenticated.value) {
      fetchDepositBonuses()
    } else {
      bonuses.value = []
    }
  })

  return {
    bonuses,
    favoriteNewDepositBonus,
    bonusesCount,
    handleDepositModalShow,
    fetchDepositBonuses,
  }
})
