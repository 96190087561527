import { useCurrencies } from '@st/payments/stores/currencies'
import { getCurrencyIconName } from '@st/payments/helpers'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { Notification, CurrencyNotification } from '../types'

export function useCurrencyNotification(notification: Ref<Notification>) {
  function isCurrencyNotificationGuard(
    notificationItem: Ref<Notification>,
  ): notificationItem is Ref<CurrencyNotification> {
    return 'amount' in notificationItem.value.data
  }

  const { format: formatWithCurrency } = useCryptoFormatter({
    currency: 'auto',
  })
  const { currencies } = storeToRefs(useCurrencies())

  const notificationCurrencyCode = computed(() => {
    if (!isCurrencyNotificationGuard(notification)) return undefined

    /* 
      бэк уберет currencyCode из всех нотификаций
      и заменит на currencyId
    */
    const currencyFromStore =
      currencies.value[notification.value.data.currencyId]
    return currencyFromStore?.code || notification.value.data.currencyCode
  })

  const notificationAmount = computed(() =>
    isCurrencyNotificationGuard(notification)
      ? formatWithCurrency(
          notification.value.data.amount,
          notificationCurrencyCode.value,
        )
      : '',
  )

  const currencyIcon = computed((): IconName | '' =>
    isCurrencyNotificationGuard(notification)
      ? getCurrencyIconName(notificationCurrencyCode.value?.toLowerCase() ?? '')
      : '',
  )

  return {
    notificationAmount,
    currencyIcon,
  }
}
