import { useUserStore } from '@st/user/stores/useUserStore'
import { useCurrencies } from './currencies'
import { useConverterStore } from './useConverterStore'
import { getCurrencyIconName } from '../helpers/index'
import type { Wallet, UserAccount, BonusAccount } from '../interfaces'

export const useAccountsStore = defineStore('accounts', () => {
  const io = useSocket()
  const userStore = useUserStore()
  const { updateUserSettings } = userStore
  const { userSettings } = storeToRefs(userStore)

  const hideNulls = computed({
    get: () => !userSettings.value?.showZeroAccounts,
    set: async (value) => {
      await updateUserSettings([{ name: 'showZeroAccounts', value: !value }])
    },
  })

  const preferredCurrencyCode = computed(
    () => userSettings.value?.preferredCurrencyCode,
  )
  const preferredAccountType = computed(
    () => userSettings.value?.preferredAccountType,
  )

  const accounts = ref<Wallet[]>([])
  const curenciesStore = useCurrencies()
  const { currencies, appCurrency } = storeToRefs(curenciesStore)
  const { convert } = useConverterStore()

  const { execute: fetchAccounts, data: accountsData } = useStFetch(
    '/account/list',
    {
      method: 'post',
      immediate: false,
      watch: false,
    },
  )

  const { execute: fetchBonusAccounts, data: bonusAccountsData } = useStFetch(
    '/bonus/summary/info',
    {
      method: 'post',
      immediate: false,
      watch: false,
    },
  )

  async function getAccountList() {
    await fetchBonusAccounts()
    await fetchAccounts()
    if (accountsData.value) {
      accounts.value = accountsData.value
    }
  }

  function resetAccounts() {
    accounts.value = []
  }

  const extendedAccounts = computed<UserAccount[]>(() =>
    accounts.value.map((account) => {
      const currency = currencies.value[account.currencyId]

      return {
        ...account,
        name: currency ? currency.name : '',
        code: currency ? currency.code : '',
        allowedForDeposit: currency.allowedForDeposit,
        allowedForWithdrawal: currency.allowedForWithdrawal,
        fiatBalance: currency
          ? convert(account.balance, {
              from: currency.code,
              to: appCurrency.value.code,
            })
          : '0',
        icon: getCurrencyIconName(currency?.codeLowerCase),
        bonusAccountAmount:
          bonusAccountsData.value?.find(
            (bonusAccount: BonusAccount) =>
              bonusAccount.currencyId === account.currencyId,
          )?.totalAmount || '0',
      }
    }),
  )

  const realAccounts = computed(() =>
    extendedAccounts.value.filter((account) => account.type === 'real'),
  )

  const filteredRealAccounts = computed(() =>
    hideNulls.value
      ? realAccounts.value.filter((account) => Number(account.balance) !== 0)
      : realAccounts.value,
  )

  function findActiveAccount() {
    return (
      extendedAccounts.value?.find(
        (account) =>
          account.code === preferredCurrencyCode.value &&
          account.type === preferredAccountType.value,
      ) ?? extendedAccounts.value?.[0]
    )
  }

  const activeAccount = ref<UserAccount>(findActiveAccount())

  watchEffect(() => {
    activeAccount.value = findActiveAccount()
  })

  function updateActiveAccount(id: number) {
    const newActiveAccount = extendedAccounts.value.find(
      (account) => account.id === id,
    )
    if (!newActiveAccount) return

    activeAccount.value = newActiveAccount

    updateUserSettings([
      { name: 'preferredCurrencyCode', value: newActiveAccount.code },
    ])
  }

  function accounBalanceSocketListener(payload: {
    accountId: number
    balanceAmount: string
  }) {
    const accountBalance = accounts.value.find(
      (item) => item.id === payload.accountId,
    )
    if (accountBalance) {
      accountBalance.balance = payload.balanceAmount
    }
  }

  function getAccountById(accountId: UserAccount['id']) {
    return extendedAccounts.value.find((account) => account.id === accountId)
  }

  io.on('accountBalance', accounBalanceSocketListener)

  return {
    getAccountList,
    accounts,
    realAccounts,
    filteredRealAccounts,
    hideNulls,
    activeAccount,
    updateActiveAccount,
    getAccountById,
    resetAccounts,
  }
})
