<template>
  <Html :lang="head?.htmlAttrs?.lang ?? ''" :dir="head?.htmlAttrs?.dir ?? ''">
    <Head>
      <Link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      />
      <Meta
        v-for="meta in head.meta"
        :id="meta.id"
        :key="meta.id"
        :property="meta.property"
        :content="meta.content"
      />
    </Head>
    <Body>
      <StModalManager :modals="modalManagerConfig" />
      <StToastManager :position="toastsPosition" />
      <NuxtLayout>
        <NuxtPage />
        <NotificationsManager />
      </NuxtLayout>
      <Cookies />
    </Body>
  </Html>
</template>

<script lang="ts" setup>
import type { AsyncComponentLoader, Component } from 'vue'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import {
  LazyAuthorizationForm,
  LazyChangeNicknameForm,
  LazyChangeNicknameFormMChangeNicknameForm,
  LazyCookiesModal,
  LazyDeleteAddressModal,
  LazyIpRestrictionModal,
  LazyMAuthorization,
  LazyMNewAddressModal,
  LazyMPaymentsModal,
  LazyMWithdrawalStatus,
  LazyNewAddressModal,
  LazyNotificationsListMNotificationsList,
  LazyPaymentsModal,
  LazyRecoveryPasswordForm,
  LazyRecoveryPasswordFormMRecoveryPassword,
  LazyRegistrationForm,
  LazySearchForm,
  LazySearchMSearchForm,
  LazyBiometricModal,
  LazyVerificationModal,
  LazyWithdrawalStatus,
  LazySumSubModal,
  LazyChangePasswordForm,
  LazyChangePasswordFormMChangePasswordForm,
  LazyChooseGameModal,
  LazyFreespinRollingModal,
  LazyFreespinRollingMFreespinRollingModal,
  LazyDepositBonusModal,
  LazyDepositBonusMDepositBonusModal,
  LazyBonusesWidgetMBonusesWidgetModal,
} from '#components' // eslint-disable-line
interface ModalConfig {
  component: AsyncComponentLoader | Component
  wrapper: 'modal' | 'drawer' | 'drawer-compact' | 'drawer-embeded-drag-line'
  closeOnClickOutside?: boolean
}

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const desktopModals: Record<string, ModalConfig> = {
  register: {
    component: LazyRegistrationForm,
    wrapper: 'modal',
    closeOnClickOutside: false,
  },
  login: {
    component: LazyAuthorizationForm,
    wrapper: 'modal',
  },
  recoverPassword: {
    component: LazyRecoveryPasswordForm,
    wrapper: 'modal',
  },
  payments: {
    component: LazyPaymentsModal,
    wrapper: 'modal',
  },
  search: {
    component: LazySearchForm,
    wrapper: 'modal',
  },
  withdrawalStatus: {
    component: LazyWithdrawalStatus,
    wrapper: 'modal',
  },
  newAddress: {
    component: LazyNewAddressModal,
    wrapper: 'modal',
  },
  deleteAddress: {
    component: LazyDeleteAddressModal,
    wrapper: 'modal',
  },
  changeNickname: {
    component: LazyChangeNicknameForm,
    wrapper: 'modal',
  },
  cookies: {
    component: LazyCookiesModal,
    wrapper: 'modal',
  },
  restriction: {
    component: LazyIpRestrictionModal,
    wrapper: 'modal',
  },
  verification: {
    component: LazyVerificationModal,
    wrapper: 'modal',
  },
  biometric: {
    component: LazyBiometricModal,
    wrapper: 'modal',
  },
  sumSubVerification: {
    component: LazySumSubModal,
    wrapper: 'modal',
  },
  changePassword: {
    component: LazyChangePasswordForm,
    wrapper: 'modal',
  },
  chooseGame: {
    component: LazyChooseGameModal,
    wrapper: 'modal',
  },
  freespinRolling: {
    component: LazyFreespinRollingModal,
    wrapper: 'modal',
  },
  depositBonus: {
    component: LazyDepositBonusModal,
    wrapper: 'modal',
  },
}

const mobileModals: Record<string, ModalConfig> = {
  register: {
    component: LazyMAuthorization,
    wrapper: 'drawer-embeded-drag-line',
  },
  login: {
    component: LazyMAuthorization,
    wrapper: 'drawer-embeded-drag-line',
  },
  recoverPassword: {
    component: LazyRecoveryPasswordFormMRecoveryPassword,
    wrapper: 'drawer',
  },
  notify: {
    component: LazyNotificationsListMNotificationsList,
    wrapper: 'drawer',
  },
  changeNickname: {
    component: LazyChangeNicknameFormMChangeNicknameForm,
    wrapper: 'drawer-compact',
  },
  search: {
    component: LazySearchMSearchForm,
    wrapper: 'drawer',
  },
  payments: {
    component: LazyMPaymentsModal,
    wrapper: 'drawer',
  },
  newAddress: {
    component: LazyMNewAddressModal,
    wrapper: 'drawer',
  },
  withdrawalStatus: {
    component: LazyMWithdrawalStatus,
    wrapper: 'drawer-embeded-drag-line',
  },
  sumSubVerification: {
    component: LazySumSubModal,
    wrapper: 'drawer',
  },
  restriction: {
    component: LazyIpRestrictionModal,
    wrapper: 'drawer-embeded-drag-line',
  },
  biometric: {
    component: LazyBiometricModal,
    wrapper: 'drawer-embeded-drag-line',
  },
  verification: {
    component: LazyVerificationModal,
    wrapper: 'drawer-embeded-drag-line',
  },
  changePassword: {
    component: LazyChangePasswordFormMChangePasswordForm,
    wrapper: 'drawer-compact',
  },
  chooseGame: {
    component: LazyChooseGameModal,
    wrapper: 'drawer-compact',
  },
  freespinRolling: {
    component: LazyFreespinRollingMFreespinRollingModal,
    wrapper: 'drawer-embeded-drag-line',
  },
  depositBonus: {
    component: LazyDepositBonusMDepositBonusModal,
    wrapper: 'drawer-embeded-drag-line',
  },
  bonuses: {
    component: LazyBonusesWidgetMBonusesWidgetModal,
    wrapper: 'drawer-compact',
  },
}
const { isMobile } = usePlatform()
const modalManagerConfig = computed(() =>
  isMobile.value ? mobileModals : desktopModals,
)

const toastsPosition = computed(() => (isMobile.value ? 'top' : 'bottom'))

/* 
  по какой-то причине после fix extends resolutions
  сторы инитятся до всех плагинов
  и внутри него нет io
*/
const io = useSocket()
const { handleFullLogout } = useUserStore()
const { activeBetListener } = useActiveBetsStore()
io.on('fullLogout', handleFullLogout)
io.on('betStatus', activeBetListener)

const { isAuthenticated, isManualLogin } = storeToRefs(useUserStore())
const { handleDepositModalShow } = useDepositBonusesStore()

watch(
  () => isAuthenticated.value,
  (isAuth) => {
    if (isAuth && isManualLogin.value) {
      handleDepositModalShow()
    }
  },
)
</script>

<style>
@import url('@st/fonts/SFProDisplay/index.css');

:root {
  scrollbar-gutter: stable;
  font-family: var(--sf-pro-display);
  background-color: var(--background-base);
}

/* hide scroll with modal */
.no-scroll {
  overflow: hidden;
  height: 100%;
}

/* hide recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
